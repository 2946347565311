/* .data_table {
    margin-left: 70px;
} */
body {
    /* font-family: 'Poppins', sans-serif; */
    font-family: Poppins, sans-serif !important;
    font-size: 15px;
    font-weight: 400;
    background-image: url(./../Asset/Background.jpg);
}

.cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e9e9e92e;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    padding-left: 10px;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap button {
    /* font-size: 11px; */
    color: white;
    text-transform: capitalize;
    background: #e9e9e92e;
    border: 0px;
    border-radius: 5px;
}

.cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn {
    padding: 10px 15px;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    padding: 3px;
    display: flex;
    gap: 70px;
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 15px;
}

.cnt_audit_outer_wrap span._btn_icon_calender {
    border-left: 0px solid #ffffff40;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr td {
    text-align: center;
    font-weight: 500;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap {
    padding: 10px;
    background: #e9e9e92e;
}

.cnt_audit_outer_wrap td.read_score_gry p {
    background: #7a283c;
    margin: 0px 28px;
    padding: 5px 0px;
    border-radius: 5px;
}

.cnt_audit_outer_wrap td.read_score_org p {
    background: #ffae1f;
    margin: 0px 28px;
    padding: 5px 0px;
    border-radius: 5px;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap tbody {
    text-transform: capitalize;
    /* font-size: 14px; */
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap tbody .wer_url_pg {
    text-transform: none;
}

.cnt_audit_outer_wrap {
    color: #ffff;
}

.cnt_audit_outer_wrap.ContentAuditWrap {
    color: #0b2033;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
    width: 5%;
}

.cnt_audit_outer_wrap img.view_td_img {
    width: 35%;
    background: #e5e6e9 !important;
    padding: 5px 15px;
    border-radius: 7px;
}

.cnt_audit_outer_wrap span._btn_icon_calender img {
    width: 18px;
    margin: 5px 3px;
}



.cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2 {
    margin: 0px;
    font-weight: 400;
}

.cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap p {
    /* font-size: 13px; */
    margin: 0px !important;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr td:first-child {
    display: flex;
    justify-content: center;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr .th_fl_report_wrap {
    width: 8%;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table {
    border-spacing: 0px 10px;
}

.cnt_hdr_blw_wrap {
    margin-left: 65px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    margin-top: 50px;
    margin-left: 30px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    height: 250px;
    margin: auto;
}

.text-error {
    color: #ffa910;
}

/*table changes suriya*/
.MuiDataGrid-columnHeadersInner svg path,
.MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable.css-gl260s-MuiDataGrid-columnHeadersInner,
.MuiDataGrid-virtualScrollerRenderZone.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone,
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar,
.MuiTablePagination-root svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root,
p#alert-dialog-slide-description {
    color: white;
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-5wly58-MuiDataGrid-root {
    /* background: #472f5e; */
    border: 0px;
}

.MuiDataGrid-cell {
    justify-content: center !important;
}

.cnt_audit_outer_wrap img.view_td_img {
    width: 64% !important;
    background: #e5e6e9 !important;
    padding: 5px 15px !important;
    border-radius: 7px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background: #fff !important;
    height: 90%;
    /* box-shadow: 1px 1px 10px 4px #5b496db3; */
    /* padding: 20px; */
    max-height: '400px';
    overflow-y: 'auto';
    display: flex;
    align-items: center;
    /* overflow: hidden; */
    /* width: 400px !important; */
}

h2#alert-dialog-title {
    text-align: center;
    color: white;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
    justify-content: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper .cnt_hdr_blw_wrap.row:first-child .col {
    display: flex;
    color: white;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root button {
    /* font-size: 14px; */
    color: white;
    text-transform: capitalize;
    background: #e9e9e92e !important;
    border: 0px;
    border-radius: 5px;
    padding: 10px 10px;
}

.read_score_gry {
    background: #7a283c;
    margin: 0px 28px;
    padding: 10px 10px;
    border-radius: 47px;
}

.lgn_container .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button:hover {
    background-color: #103658 !important;
    color: #fff;
}

.read_score_org {
    background: #ffae1f;
    margin: 0px 28px;
    padding: 10px 10px;
    border-radius: 47px;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
    /* padding-bottom: 101px; */
}

.react-datepicker__close-icon::after {
    background-color: #f19b00 !important;
}

.css-5wly58-MuiDataGrid-root .MuiDataGrid-withBorderColor {
    /* border-color: rgb(224 224 224 / 0%) !important; */
}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders,
.MuiDataGrid-virtualScroller.css-axafay-MuiDataGrid-virtualScroller {
    background: #472f5e;
    /* height:unset; */
}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
    margin-bottom: 0px;
}

.read_score_org_faile {
    background: #fa0404;
    /* margin: 41px 65px; */
    padding: 3px 36px;
    border-radius: 7px;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-family: "Montserrat Alternates", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    /* font-size: 1rem; */
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.read_score_gry_success {
    background: #3cf503;
    /* margin: 41px 65px; */
    padding: 3px 36px;
    border-radius: 7px;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-family: "Montserrat Alternates", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    /* font-size: 1rem; */
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: black;
}

button.buttonView {
    padding-bottom: 5px;
    padding-top: 5px;
    color: white;
    background: #e9e9e942;
    border-radius: 8px;
    width: 150px;
    padding: 10px;
}

img.view_std_img {
    width: 10% !important;
    margin-left: 15px;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap_detail img {
    width: 13%;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap_detail {
    display: flex;
    justify-content: flex-end;
}

.cnt_hdr_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.cnt_hdr_content_box {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.warning_back_schema {
    width: 165px;
    padding: 0 10px;
    background: #ffae1f !important;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
}

.cnt_hdr_content_table .schemaOutputeso {
    padding: 0px 0px !important;
    margin-top: 0px !important;
}

.error_back_schema {
    margin-left: 50px;
    width: 165px;
    padding: 0 10px;
    background: #fa0404 !important;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
}

.schema_missing_waring_wrap {
    width: 165px;
    padding: 0 10px;
    background: #e9e9e942 !important;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 25px;
}

.schema_missing_waring_wrap h4 {
    margin: 0;
}

.cnt_hdr_content {
    padding-bottom: 30px;
    color: #fe874e;
}

.cnt_hdr_content_table {
    background: #e9e9e942 !important;
    padding: 30px 0px;
}

.cnt_hdr_content_box_outer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cnt_hdr_content_table table.table tr {
    vertical-align: initial;
}

.cnt_hdr_content_table table.table tr td {
    padding-bottom: 12px;
}

.cnt_hdr_content_table table.table tr td:first-child {
    min-width: 155px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    /* overflow: visible; */
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    background: #fdfdfd;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ff601f;
    border-radius: 10px;
}

/* Handle on hover */
.over_all_container::-webkit-scrollbar {
    display: none;
}

/*differentiate*/
div#schemaOutput ul li>.child li>.child li .key.newClass:before {
    padding-right: 2px;
    position: absolute;
    content: "";
    left: 0px;
    width: 0px;
    height: 30px;
    background: #103658;
}

div#schemaOutput ul li>.child li>.child li .key.newClass {
    position: relative;
    padding-left: 10px;
}

div#schemaOutput ul li.parent span.tle_key {
    color: #ff601f;
}

.frm_container table th label {
    text-align: left;
    font-size: 18px;
}

.frm_container table th {
    padding-bottom: 20px;
}

.frm_container table td {
    /* padding-bottom: 30px; */
    width: 78%;
}

@media(min-width:1440px) {
    .frm_container table td {
        width: 54%;
        margin-right: 106px;
    }
}




.frm_container input[type="text"] {
    height: 37px;
    background: white;
    border: 1px solid #ff601f;
    width: 250px !important;
    padding: 0 0 0 17px !important;
}

@media(max-width:500px) {
    .frm_container input[type="text"] {
        width: 181px !important;
    }
}


@media(max-width:768px) {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        width: 400px !important;
    }

    .frm_container table th label {
        text-align: left;
        /* font-size: 15px; */
    }

    .websitetype_inner label {
        /* font-size: 15px; */
    }

    .form_next button {
        border-radius: 10px;
        /* font-sizee: 20px; */
        position: relative;
        color: #fff;
        width: 40%;
        /* margin-left: 20px; */
        padding: 20px 20px;
        background: #103658;
    }
}

@media(max-width:500px) {
    .run_report_now_modal .radio-btn .hobbies-icon {
        top: 60% !important;
        left: 50% !important;
    }

    .run_report_now_modal .radio-btn .hobbies-icon h3 {
        /* color: #fff; */
        /* font-size: 12px !important; */
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .run_report_now_modal .radio-btn .hobbies-icon img {
        display: block;
        width: 53%;
        margin-bottom: 20px;
        margin-left: 22px;
    }

    .acc-dt-rpt {
        display: block !important;
    }

    .frm_container table th label {
        /* font-size: 12px; */
    }
}

@media(max-width:400px) {
    .tab_otr_outer .frm_otr_wrap input#email_Phone {
        /* padding: 28px 3px 28px 6px !important; */
    }

    .tab_otr_outer .frm_otr_wrap input#password {
        /* padding: 28px 3px 28px 6px !important; */
    }
}

.heading_font {
    font-family: Space Grotesk, sans-serif;
    /* font-size: 18px;*/
    /* font-size: 180%; */
    font-weight: 700;
}

.sub_heading_font {
    font-family: 'Poppins', sans-serif;
    /* font-size: 18px; */
    font-weight: 700;
}

.sub_heading1_font {
    font-family: 'Poppins', sans-serif;
    /* font-size: 17px; */
    font-weight: 700;
}

.text_font {
    font-family: 'Poppins', sans-serif;
    /* font-size: 15px; */
    font-weight: 400;
}

.domain-verify-alert {
    border-radius: 18px !important;
    border-color: white !important;
    top: 10px !important;
}

.common-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 11px;
    padding: 20px;
}

@media (max-width: 768px) {

    .common-card-container {
        /* flex-direction: column; */
        align-items: center;
    }

    .common-card-wrapper-style {
        width: 100%;
        max-width: 100%;
    }

}

.common-card-wrapper-style {
    position: relative;
    width: calc(50% - 11px);
    max-width: 250px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 52px;
    cursor: pointer;
}

.common-card-icon-container {
    width: 65px;
    height: 65px;
    position: absolute;
    top: -39px;
    left: 35%;
    background: #ff6600;
    box-shadow: 0 4px 6px rgb(20 7 0 / 36%);
    border-radius: 50%;
    padding: 5px;
}

@media(max-width:320px) {
    .common-card-icon-container {
        left: 18%;
    }
}

@media(min-width:350px) and (max-width:400px) {
    .common-card-icon-container {
        left: 24%;
    }
}

@media(min-width:401px) and (max-width:500px) {
    .common-card-icon-container {
        left: 29%;
    }
}

.common-card-icons {
    position: absolute;
    top: 15px;
    left: 55%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
}

.common-card-subtitle {
    padding: 50px 20px 20px;
    border-radius: 8px;
    border: 2px solid #ff6600;
    background-color: white;
    transition: 0.3s;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.common-card-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #0B2033;
}

.common-card-value {
    font-size: 16px;
    font-weight: bold;
    color: #0B2033;
}

.common-card-icon-img {
    filter: invert(0%);
    pointer-events: none;
}



.common-card-subtitle.active {
    background-color: #ff6600;
}

.common-card-icon-container.active {
    background-color: #fff;
}

.common-card-icon-img.active {
    filter: invert(55%) sepia(87%) saturate(500%) hue-rotate(-27deg);
}

.common-card-wrapper-style:hover .common-card-icon-container {
    background-color: #fff;
}

.common-card-wrapper-style:hover .common-card-icon-img {
    filter: invert(55%) sepia(87%) saturate(500%) hue-rotate(-27deg);
}

.subheader-timedate {
    font-size: 14px;
    letter-spacing: 0.8px !important;
    color: rgba(255, 96, 31, 0.76) !important;
}

.pie-chart-outer {
    /* height: 390px; */
    height: auto;
    max-width: 600px;

    box-shadow: 0px 0px 10px -3px #0b203394;
    width: 30%;
    background: #fff;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    /* margin-top: 0px; */
    padding: 30px !important;
    margin: 50px auto auto auto;


    padding-bottom: -11px !important;

    min-height: unset !important;
}

.pie-chart-inner {
    /* height: 350px; */
    /* width: 500px; */
    height: auto;
    width: auto;
}

@media (max-width: 500px) {
    .pie-chart-outer {
        width: auto;
        padding: 10px !important;
    }
}

.pdf-download-type {
    padding: 12px;
    place-items: center;
    box-shadow: 0px 0px 5px 0px #a4a6a8;
    text-align: center;
    border-radius: 8px;
}

@media (max-width: 350px) {
    .pdf-download-type {
        padding: 0px;
    }
}

.pdf-download-card-container {
    display: flex;
    gap: 20px;
    margin: 5px;
}
