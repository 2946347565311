/*mychanges*/
/* @import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:wght@300;400;700;900&family=Poppins:ital,wght@0,100;0,300;0,800;0,900;1,500;1,800;1,900&family=Roboto:wght@300;400;500;700;900&family=Source+Sans+3:wght@200&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');


body {
  /* font-family: 'Montserrat Alternates', sans-serif !important; */
  font-family: Poppins, sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  background: #ffffff !important;
  overflow-x: hidden !important;

}

button.link_rn_rpt_btn,
select#drp_inner_top_wrap,
select#drp_inner_bottom_wrap,
.run_rpt_btn,
.input_domain_field,
.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-cell {
  /* font-family: 'Montserrat Alternates', sans-serif !important; */
  font-family: Poppins, sans-serif !important;
  /* font-size: 15px; */
  font-weight: 400;

}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root,
.css-qfso29-MuiTypography-root-MuiDialogContentText-root,
.react-dropdown-select-input,
.rn_rpt_btn,
.greenbutton,
.redbutton,
button,
#otppas,
input,
select {
  /* font-family: 'Montserrat Alternates', sans-serif !important; */
  font-family: Poppins, sans-serif !important;

}

/* .logo_wrap {
    visibility: hidden;
} */
.over_all_container .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: #0b2033;
}

/* body:after {
    top: 0px;
    content: "";
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #6f438d1f !important;
    height: 100%;
} */
/*end here*/

body {
  margin: 0px;
  background-image: url(./Asset/Background2.png) !important;
  background-repeat: no-repeat !important;
  /* Prevents repeating */
  background-size: cover !important;
  /* Scales image to cover the entire viewport */
}

button {
  cursor: pointer;
}


/*side nav and header*/
.side_menu_outer_wrap .nav_out_wrap {
  display: block;
}

.side_menu_outer_wrap {
  position: fixed;
  /* Fixes the menu in place */

  float: left;
  align-items: center;
}

.cnt_audit_outer_wrap {
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 1200px; */
}

/* span.sub_nav_key_wrap span:after,span.sub_nav_tech_wrap span:after,span.sub_nav_cntt_wrap span:after {
    width: 10px;
    height: 2px;
    content: "";
    left: 27px;
    margin-top: 9px;
    position: absolute;
    background: #adabb3;
    display: inline-block;
} */
/* .cnt_audit_outer_wrap.plagiarisamdetail_otr_wrap::-webkit-scrollbar {
    display: none;

}
.cnt_audit_outer_wrap.plagiarisamdetail_otr_wrap {
-ms-overflow-style: none;
scrollbar-width: none;
} */
span.side_nav_txt.sub_nav_key_txt:hover,
span.side_nav_txt.sub_nav_tech_txt:hover,
span.side_nav_txt.sub_nav_cntt_txt:hover {
  margin-left: 7px;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

span.side_nav_txt.sub_nav_key_txt:hover::after,
span.side_nav_txt.sub_nav_cntt_txt:hover::after,
span.side_nav_txt.sub_nav_tech_txt:hover::after {
  left: 35px;
  background: #ffae1f;
}

/* span.side_nav_txt.sub_nav_key_txt:hover span.sub_nav_key_wrap span:after,span.sub_nav_tech_wrap span:after,span.sub_nav_cntt_wrap span:after {
    left: 17px;
} */

.sub_nav_key_wrap.hidden,
.sub_nav_cntt_wrap.hidden,
.sub_nav_tech_wrap.hidden {
  animation: fadeOut 0.2s ease 0s 1 normal forwards;
  -webkit-animation: fadeOut 0.2s ease 0s 1 normal forwards;
  -moz-animation: fadeOut 0.2s ease 0s 1 normal forwards;
  display: none !important;
}

.sub_nav_key_wrap.visible,
.sub_nav_cntt_wrap.visible,
.sub_nav_tech_wrap.visible {
  animation: fadeIn 0.2s ease 0s 1 normal;
  -webkit-animation: fadeIn 0.2s ease 0s 1 normal;
  -moz-animation: fadeIn 0.2s ease 0s 1 normal;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  padding-left: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.header_wrap .logo_wrap img {
  display: none;
  padding: 14px;
  width: 100px;
  margin-left: 15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000073;
  border-radius: 7px;
}

.side_menu_inner_wrap .nav_das_icon.side_nav_icons:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 25px;
  background-image: url(./Asset/white_color.png);
  /* background-image: url(./Asset/sidenav_black.svg); */
  background-size: 312px 79px;
  background-position: -345px -3px;
}

.side_menu_inner_wrap .side_nav_icons:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 28px;
  /* background-image: url(./Asset/white_color.png); */
  transition: background-image 1.6s ease;
  /* background-image: url(./Asset/light_grey.png); */
  background-image: url(./Asset/white_color.png);
}

.side_menu_inner_wrap .side_nav_icons.nav_orange_icons:before {
  /* background-image: url(./Asset/New_Assert/orange_icons.png); */
  background-image: url(./Asset/white_color.png);
}

/* .side_menu_inner_wrap .nav_key_icon:before{
    background-size: 206px 111px;
    background-position: -250px -7px;
}
.side_menu_inner_wrap .nav_cntt_icon:before{
    background-size: 206px 111px;
    background-position: -276px -7px;
}
.side_menu_inner_wrap .nav_tech_icon:before{
    background-size: 206px 111px;
    background-position: -302px -7px;
}
.side_menu_inner_wrap .nav_balink_icon:before{
    background-size: 206px 111px;
    background-position: -327px -7px;
}
.side_menu_inner_wrap .nav_scl_mdl_icon:before{
    background-size: 199px 83px;
    background-position: -346px 2px;
}
.side_menu_inner_wrap .nav_bran_rev_icon:before{
    background-size: 206px 111px;
    background-position: -238px -62px;
}
.side_menu_inner_wrap .nav_gle_an_ce_icon:before{
    background-size: 206px 111px;
    background-position: -265px -62px;
}
.side_menu_inner_wrap .nav_act_itm_icon:before{
    background-size: 190px 111px;
    background-position: -269px -66px;
}
.side_menu_inner_wrap .nav_site_gen_icon:before{
    background-size: 190px 111px;
    background-position: -293px -69px;
}
.side_menu_inner_wrap .nav_set_icon:before{
    background-size: 205px 106px;
    background-position: -346px -62px;
} */
.side_menu_inner_wrap .nav_key_icon:before {
  background-size: 394px 114px;
  background-position: -493px -10px;
}

.side_menu_inner_wrap .nav_cntt_icon:before {
  background-size: 394px 114px;
  background-position: -544px -10px;
}

.side_menu_inner_wrap .nav_tech_icon:before {
  background-size: 320px 92px;
  background-position: -479px -4px;
}

.side_menu_inner_wrap .nav_balink_icon:before {
  background-size: 383px 102px;
  background-position: -625px -8px;
}

.side_menu_inner_wrap .nav_scl_mdl_icon:before {
  background-size: 281px 78px;
  background-position: -495px 0px;
}

.side_menu_inner_wrap .nav_bran_rev_icon:before {
  background-size: 291px 91px;
  background-position: -342px -50px;
}

.side_menu_inner_wrap .nav_gle_an_ce_icon:before {
  background-size: 291px 91px;
  background-position: -381px -52px;
}

.side_menu_inner_wrap .nav_act_itm_icon:before {
  background-size: 291px 91px;
  background-position: -421px -52px;
}

.side_menu_inner_wrap .nav_site_gen_icon:before {
  background-size: 291px 91px;
  background-position: -460px -52px;
}

.side_menu_inner_wrap .nav_site_gen_icon:before {
  background-size: 291px 91px;
  background-position: -460px -52px;
}

.side_menu_inner_wrap .nav_set_icon:before {
  background-size: 291px 91px;
  background-position: -497px -56px;
}

.side_menu_inner_wrap .side_nav_txt {
  /* color:#dbd5de; */
  color: #ffffff;
  text-transform: capitalize;
}

.side_menu_inner_wrap span.nav_hover_cnt_wrap {
  padding: 6px 3px 0px 3px;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

.side_menu_inner_wrap span.nav_out_wrap {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  display: flex;
  padding: 7px 5px;
  flex-wrap: wrap;
}

span.nav_txt_otr_wrap {
  padding-top: 8px;
}

span.nav_txt_outer_wrap {
  display: flex;
  flex-direction: column;
}

.side_menu_inner_wrap .side_nav_txt {
  display: none;
}

.side_menu_inner_wrap .side_nav_txt:hover {
  color: #000000;
}

.side_menu_inner_wrap .das_outer_wrap,
.side_menu_inner_wrap .key_tech_wrap,
.side_menu_inner_wrap .back_brand_wrap {
  /* border-bottom: 1px dashed white; */
  border-bottom: 3px solid #ffcab5;
  width: 100%;
}

.side_menu_inner_wrap {
  white-space: nowrap;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.side_nav_txt {
  /* font-size: 14px; */
  padding-left: 10px;
}

svg.svg-inline--fa.fa-chevron-right.nav_key_icon,
svg.svg-inline--fa.fa-chevron-right.nav_cntt_icon,
svg.svg-inline--fa.fa-chevron-right.nav_tech_icon {
  padding-left: 6px;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

.cnt_hdr_content_data a {
  color: #ffae1f;
}

.hovered svg.svg-inline--fa.fa-chevron-right.nav_tech_icon,
.hovered svg.svg-inline--fa.fa-chevron-right.nav_cntt_icon,
.hovered svg.svg-inline--fa.fa-chevron-right.nav_key_icon {
  transform: rotate(89deg);
  padding: 0px 9px;

}

/*header*/
.top_icons_grp img {
  display: none;
  width: 24px;
  cursor: pointer;
}

.header-outer-wrap {
  z-index: 999;
  top: 0px;
  background-color: #E6E7EB;

}

.header_wrap {
  top: 0px;
  position: sticky !important;
  /* background: #452e5e; */
  background: url(./Asset/Head.png);
  height: 70px;
  /* margin-left: auto;
    margin-right: auto;
    max-width: 1200px; */

}

.side_menu_outer_wrap::-webkit-scrollbar {
  display: none;
}

.side_menu_outer_wrap {
  /* background-image: url(./Asset/Background.jpg); */
  z-index: 99;
}

.schemaOutputeso li .val {
  width: 1200px !important;
  /* margin-left: 10px; */
}

.add_keyword_button_wrap {
  background: #fe874e !important;
  opacity: 1;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px)
}

.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before {
  border-bottom: 1px solid rgb(11 32 51);
}

/* .side_menu_outer_wrap {
    max-height: 100%;
    overflow: scroll;
    position: sticky;
    top: 61px;
    padding-bottom: 70px;
} */
.side_menu_outer_wrap {
  position: sticky !important;
  z-index: 9999;
  top: 0px;
  position: -webkit-sticky;
  /* background: #000000fc; */
  background: #ff601f;
  /* background-image: url('../../Icons/sidenavbackground.png'); */
  border-right: inset;
  height: 100vh;
  /* width: 250px; Set a fixed width */
  /* max-height: 100vh; */
  overflow-y: auto;
}

.header_wrap {
  display: flex;
  justify-content: space-between;
  background-color: #E6E7EB;
  /* position: relative */
}

.bx_wrap_days p {
  /* font-size: 14px; */
  color: white;
  text-transform: capitalize;
  background: #000000;
  border: 0px;
  padding: 17px 15px;
  border-radius: 9px;
}

select#drp_inner_top_wrap {
  /* margin-right:50px; */
  cursor: pointer;
}

.header_inner_top_wrap,
.header_inner_btm_wrap {
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 30px;
}

select#drp_inner_top_wrap,
select#drp_inner_bottom_wrap {
  /* font-size: 14px; */
  color: #050505;
  text-transform: capitalize;
  background: white;
  border: 1px solid #ff601f;
  border-radius: 5px;
  padding: 10px 60px 10px 10px;
}

/* .header_inner_btm_wrap {
    gap: 185px;
} */
.top_icons_grp .mail_img {
  padding-right: 10px;
  padding-left: 15px;
}

.drp_outer_wrap {
  display: flex;
  position: relative;
}

/* .drp_inner_tp_wrap:after {
    content: "";    
    color: #fff;
    width: 2px;
    height: 38px;
    top: 0px;
    left: 100px;
    display: inline-block;
    position: absolute;
    background: #f0f8ff73;
} */
.drp_inner_btm_wrap:after {
  content: "";
  color: #fff;
  width: 2px;
  height: 38px;
  top: 0px;
  right: 42px;
  display: inline-block;
  position: absolute;
  background: #f0f8ff73;
}

.select_category {
  background-image: url(./Asset/arrow.png) !important;
  background-position: calc(100% - 10px) center !important;
  background-repeat: no-repeat !important;
  background-size: 24px auto !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  color: #939598;
  align-items: center;
}


select::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin-right: 20px;
}

/*side nav and header changes end*/
.cnt_audit_outer_wrap .cnt_hdr_top_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cnt_audit_outer_wrap .cnt_hdr_top_wrap {
  padding: 25px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e9e9e92e;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
  padding-left: 10px;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap button {
  /* font-size: 11px; */
  color: #000000;
  text-transform: capitalize;
  background: #e9e9e92e;
  border: 0px;
  border-radius: 5px;
}

.cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn {
  padding: 10px 15px;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
  padding: 3px;
  display: flex;
  gap: 70px;
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 15px;
  overflow: hidden;
  position: relative;
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:hover {
  background: #c4d4e3 !important;
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
  position: absolute;
  content: "";
  width: 1px;
  translate: 65px;
  height: 38px;
  background: #000000;
  margin-left: 10px;
}

.drp_inner_tp_wrap {
  position: relative;
  overflow: hidden;
}

.drp_inner_tp_wrap:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 52px;
  background: #ff601f;
  translate: -45px;
  z-index: 2;
}

.cnt_audit_outer_wrap span._btn_icon_calender {
  border-left: 0px solid #ffffff40 !important;
  padding-left: 10px;
  padding-top: 6px;
  cursor: pointer;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr td {
  text-align: center;
  font-weight: 500;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap {
  padding: 10px;
  background: #e9e9e92e;
}

.cnt_audit_outer_wrap td.read_score_gry p {
  background: #7a283c;
  margin: 0px 28px;
  padding: 5px 0px;
  border-radius: 5px;
}

.analysis_summary_table .read_score_red {
  background: #ff1901;
  margin: 0px !important;
  padding: 5px 10px;
  border-radius: 5px;
}

.analysis_summary_table .read_score_org {
  margin: 0px !important;
}

.read_score_red {
  background: #ff1901;
  margin: 0px 28px;
  padding: 5px 10px;
  border-radius: 5px;
}

.cnt_audit_outer_wrap td.read_score_org p {
  background: #ffae1f;
  margin: 0px 28px;
  padding: 5px 0px;
  border-radius: 5px;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap tbody {
  text-transform: capitalize;
  /* font-size: 14px; */
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap tbody .wer_url_pg {
  text-transform: none;
}

.cnt_audit_outer_wrap {
  color: #ffff;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
  width: 2%;
}

.cnt_audit_outer_wrap img.view_td_img {
  width: 35%;
  background: #e5e6e9 !important;
  padding: 5px 15px;
  border-radius: 7px;
}

.cnt_audit_outer_wrap img.view_td_img:hover {
  background: #00000047 !important;
}

.cnt_audit_outer_wrap span._btn_icon_calender img {
  width: 18px;
  margin: 5px 3px;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap p {
  /* font-size: 13px; */
  margin: 0px !important;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr td:first-child {
  display: flex;
  justify-content: center;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table tr .th_fl_report_wrap {
  width: 8%;
}

.cnt_audit_outer_wrap .cnt_hdr_tbl_wrap table {
  border-spacing: 0px 10px;
}

.cnt_hdr_blw_wrap {
  margin-left: 65px;
}

/* .cnt_hdr_top_wrap {
    margin-left: 66px;
    margin-top: 66px;
} */
.ReactModal__Content.ReactModal__Content--after-open {
  margin-top: 50px;
  margin-left: 30px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  height: 250px;
  margin: auto;
}

.text-error {
  /* color:#ffffff !important; */
  color: #ff0000 !important
}

/*table changes suriya*/
.MuiDataGrid-columnHeadersInner svg path,
.MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable.css-gl260s-MuiDataGrid-columnHeadersInner,
.MuiDataGrid-virtualScrollerRenderZone.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone,
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar,
.MuiTablePagination-root svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root,
p#alert-dialog-slide-description {
  color: white;
}

.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-5wly58-MuiDataGrid-root {
  /* background: #472f5e; */
  border: 0px;
}

.MuiDataGrid-cell {
  justify-content: center !important;
}

.cnt_audit_outer_wrap img.view_td_img {
  width: 64% !important;
  background: #e5e6e9 !important;
  padding: 5px 15px !important;
  border-radius: 7px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #472f5e !important;
  /* height: 50%;
    width:25%;  */
}

.css-wmy1p7-ReactDropdownSelect {
  margin-right: 15px;
}

.css-dpcmdr-DropDown {
  overflow-y: scroll !important;
  height: 150px;
  min-height: 150px;
}

/**/

.MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child,
.MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child font {
  /* display: block; */
  /* color: #fff; */
  max-height: 414px !important;
  overflow: unset;
  font-weight: 600;
  /* font-size: 13px; */
}

.plagirisam_detail .MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child,
.MuiDataGrid-row .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:first-child font {
  display: block;
}

.plagiarisamdetail_otr_wrap span.full_title,
.plagiarisamdetail_otr_wrap span.full_url {
  color: #ff601f;
}

.full_title_inr_txt {
  color: #0b2033;
}

.MuiDataGrid-row {
  max-height: unset !important;
  padding: 10px 0px;
}

.page_health_table .MuiDataGrid-row {
  padding: 10px 0px;
}

.MuiDataGrid-row span.full_url {
  white-space: initial;
  overflow-wrap: break-word !important;
}

/**/
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  outline: none;
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor a {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  /* width: fit-content; */
  padding-right: 0px;
  overflow: auto;
}

.css-ypiqx9-MuiDialogContent-root {
  /* margin-left: 30px; */
  padding: 0px 5px !important;
}

h2#alert-dialog-title {
  text-align: center;
  color: white;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
  justify-content: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper .cnt_hdr_blw_wrap.row:first-child .col {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper .cnt_hdr_blw_wrap.row:first-child .col::-webkit-scrollbar {
  display: none !important;
}

.css-1emkt0j-DropDown {
  overflow-y: scroll !important;
}

span.react-dropdown-select-item.css-z2hyoe-ItemComponent.evc32pp0.accessbility_report_statistics {
  color: #000;
  background: #ebc0ab;
}

.drp_inner_tp_wrap option {
  color: #0b2033;
  background: #ffffff;
}

span.react-dropdown-select-item.css-z2hyoe-ItemComponent.evc32pp0 {
  color: #0b2033;
  background: #ffffff;
}

span.react-dropdown-select-item.css-z2hyoe-ItemComponent.evc32pp0:hover {
  background: #fe874e !important;
  color: #fff;
}

.drp_inner_tp_wrap option:checked,
.drp_inner_tp_wrap option:hover {
  background-color: #fe874e !important;
}

.MuiDataGrid-virtualScroller .css-b5h07o .ant-empty-img-1,
.MuiDataGrid-virtualScroller .css-b5h07o .ant-empty-img-2 {
  fill: #fe874e;
}

.MuiDataGrid-virtualScroller .css-b5h07o .ant-empty-img-3 {
  fill: #0b2033;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root button {
  /* font-size: 14px; */
  color: white;
  text-transform: capitalize;
  background: #e9e9e92e !important;
  border: 0px;
  border-radius: 5px;
  padding: 10px 10px;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
  /* padding-bottom: 40px; */
}

.react-datepicker__close-icon::after {
  background-color: #f19b00 !important;
}

.css-5wly58-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: rgb(224 224 224 / 0%) !important;
}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders,
.MuiDataGrid-virtualScroller.css-axafay-MuiDataGrid-virtualScroller {
  background: transparent linear-gradient(351deg, #FFFFFF40 0%, #FFFFFF0D 85%) 0% 0% no-repeat padding-box;
  /* height:unset; */
}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
  margin-bottom: 0px;
  /* position: sticky; */
}

.read_score_org_faile {
  background: #fa0404;
  /* margin: 41px 65px; */
  padding: 3px 36px;
  border-radius: 7px;
  /* font-family: "Montserrat Alternates","Helvetica","Arial",sans-serif; */
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  /* font-size: 1rem; */
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.read_score_gry_success {
  background: #3cf503;
  /* margin: 41px 65px; */
  padding: 3px 36px;
  border-radius: 7px;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  /* font-size: 1rem; */
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: black;
}

.page_health h1 {
  /* font-size: 20px; */
}

button.buttonView {
  padding-bottom: 5px;
  padding-top: 5px;
  color: white;
  background: #e9e9e942;
  border-radius: 20px;
}

img.view_std_img {
  width: 10% !important;
  margin-left: 15px;
}

.cnt_audit_outer_wrap {
  /* margin-left: 66px; */
  /* margin-top: 66px; */
  background: #e5e6e9;
}

.box_wrap {
  /* max-width: 1110px; */
  margin: 0 auto;
  width: 100%;
  overflow: auto;
}

.schema_anaysis_outer_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formval {
  /* height: 23px; */
  padding: 7px;
  /* margin-left: 1pc; */
  border-radius: 10px;
}

/* .custom-data-grid .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    display: block;
    margin-left: 27%;
  } */

.on_page_table button {
  cursor: pointer;
}

.custom-data-grid .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2) {
  display: block;
  /* margin-left: 25%; */
}

.ch_date_btn,
.rn_rpt_btn {
  cursor: pointer;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #22222257;
  z-index: 100000;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.sub_nav_cntt_txt,
.side_nav_txt {
  cursor: pointer;
}

.competitor_test_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  height: 60%;
}

.competitor_test_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: -webkit-fill-available;
}

.job_date_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  /* max-width: 470px !important; */
  height: 50%;
  border-radius: 5px;
}

.competitor_test_filter .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  /* display: flex; */
  padding-right: 0px;
  overflow: auto;
}

/* .header_wrap~div {
    overflow: auto !important;
   } */

/*  */
.over_all_container {
  background-color: hwb(228 90% 8%);
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  /* max-height: auto; */
  /* margin-left: auto;
    margin-right: auto;
    max-width: 1200px; */

}

.header_container {
  padding-left: 40px;
  position: sticky !important;
}

.side_menu_outer_wrap .side_menu_inner_wrap .sub_nav_tech_txt,
.side_menu_outer_wrap .side_menu_inner_wrap .sub_nav_cntt_txt,
.side_menu_outer_wrap .side_menu_inner_wrap .sub_nav_key_txt {
  padding: 5px 0px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiDataGrid-iconSeparator.css-i4bv87-MuiSvgIcon-root {
  display: none !important;
}

.hide_option svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
  display: none;
}

.hide_option .MuiDataGrid-menuIconButton {
  display: none;
}

.missing_canocnical_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell {
  display: block !important;
}

.all_page_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell {
  display: block !important;
  margin-left: 4px;
}

.page_health_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2) {
  /* display: block; */
  display: flex;
  padding-left: 70px;

}

.tab_otr_outer.reset_page_container {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1200px;
}

.accessbility_score_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2) {
  display: block;
  padding-left: 70px;

}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1ps6pg7-MuiPaper-root {
  width: 70%;
}

.spell_checker_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2) {
  display: block;
  padding: 40px;
}

.plagarism_checker_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(3) {
  display: block;
  padding: 25px;
}

.image_detail_table .MuiDataGrid-cell.MuiDataGrid-cell--textFalse.MuiDataGrid-withBorderColor:nth-of-type(2) {
  display: block;
  padding-left: 70px;

}

.image_detail_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(3) {
  display: block;
  padding-left: 70px;

}

.MuiDataGrid-menuIconButton {
  display: none !important;
}

.accessbility_score_warning_wrap {
  width: 306px !important;
}

.accessbility_report_statistics {
  text-align: center;
}

.page_statistics_row {
  display: flex;
}

.page_statistics_row .col1 {
  width: 60%;
}

.page_statistics_row .col2 {
  width: 40%;
}

.page_statistics_row .data_row {
  border-bottom: 1px solid #afa6a6;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.page_statistics_row .data_row .icon {
  position: relative;
  right: 4px;
  top: 5px;

}

.error_content_count p {
  color: red;
  font-weight: 900;
}

.warning_content_count p {
  color: #ffffff;
  font-weight: 900;
}

.notices_content_count p {
  color: #0b2033;
  font-weight: 900;
}

.schemaOutputeso {
  font-weight: 600;
  overflow: auto;
}

/* .val{
  margin-bottom: 50px;
} */
.schemaOutputeso ul .parent {
  color: #0b2033;
}

.schemaOutputeso .key_val_row {
  display: flex;
  /* height: 60px; */
  min-height: 45px;
  margin-bottom: 15px;

  /* align-items: center; */
}

.schemaOutputeso li .key {
  width: 20% !important;
}

.schemaOutputeso li .val {
  width: 70% !important;
}

/* .schemaOutputeso .parent{
    padding: 25px 0px;
} */
div#schemaOutput ul {
  list-style: none;

}

div#schemaOutput .parent_ul {
  border-bottom: 0.1px solid #595252;
}

.error_schema {
  color: red;
}

.side_nav_txt.active {
  color: #000000 !important;
}

a.side_nav_txt {
  text-decoration: none;
}

a.nav_txt_otr_wrap {
  padding-top: 6px !important;
}

.google_apple_log_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10%;
}

/* .apple-sign-in {
    display: flex;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    padding: 8px 12px;
    border: none;
    background: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 3px;
    gap: 6px;
 
  } */
.apple-sign-in {
  z-index: 99;
  position: absolute;
  display: flex;
  align-items: center;
  appearance: none;
  padding: 24px 76px;
  background: #fff0;
  cursor: pointer;
  border-radius: 7px;
  top: 0px;
  left: 0px;
}

.apple_login_outer {
  position: relative;

}

.cnt_hdr_blw_wrap.add_keyword_button_wrap {
  padding: 6px !important;
  display: flex;
  gap: 30px;

}

.keyword_add_form .form .two_col {
  display: flex;
  gap: 30px;
  margin-bottom: 35px;
  justify-content: center;
}

.keyword_add_form .form .two_col .col1,
.keyword_add_form .form .two_col .col2 {
  gap: 10px;
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form-container [type="text"] {
  /* font-size: 15px; */
  width: 250px !important;
  /* height: 40px; */
}

.keyword_add_form .form .four_col {
  display: flex;
  gap: 30px;
}

.keyword_add_form .form .two_col .col1 input {
  width: 100%;
  height: 31px;
}

.keyword_add_form .form .four_col {
  display: flex;
  gap: 30px;
  margin-bottom: 35px;
}

.keyword_add_form .form .four_col .col {
  width: 16%;
}

.keyword_add_form .click_to_add.rn_rpt_btn {
  padding: 10px 15px !important;
}

.keyword_add_form .form .four_col .col .click_to_add {
  color: #F5F5F5;
  background: transparent linear-gradient(8deg, #FFFFFF40 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  padding: 12px;
  height: 40px;
  width: 50%;
  margin-top: 20px;

}

.keyword_add_form .form .four_col .col.button_col {

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.keyword_add_form .form .adddatatotable .row {
  display: flex;
  margin-bottom: 10px;
  background: #3d32326b;
  padding: 4px;
  width: 96%;
  border-radius: 4px;
}

.keyword_add_form .form .adddatatotable .row .col {
  padding: 10px 8px;
  margin-right: 10px;
  word-wrap: break-word;
  width: 20%;
  color: #fff;
}

.keyword_add_form .form .adddatatotable svg {
  cursor: pointer;
}

.keyword_add_form .form .adddatatotable .row .col.col5 {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.keyword_add_form .form .branded_term .row {
  display: flex;
  margin-bottom: 35px;
}

.keyword_add_form .form .branded_term .row .brandhead .col1 {
  width: 30%;
  height: 30px;
  display: flex;
  align-items: center;
}


.keyword_add_form .form .branded_term .row .brandhead .col2 {
  width: 60%;
}

.keyword_add_form .form .branded_term .row .col2 input {
  width: 100%;
  height: 30px;
}

.keyword_add_form .form .branded_term .row .brandhead {
  width: 45%;
  display: flex;

}

.keyword_add_form .form .branded_term .row .inputhead {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;


}

.keyword_add_form .form .branded_term .row .inputhead input,
svg {
  /* cursor: pointer; */
  padding-left: 10px;
}

.keyword_add_form .form .branded_term .row .brandedtermremove {
  cursor: pointer;
  padding-left: 10px;
}

.keyword_add_form .add_keywords_parent {
  padding-right: 20px;
  text-align: center;
}

.keyword_add_form .form .add_keywords_parent .add_keywords_form .add_keywords,
.keyword_add_form .form .add_keywords_parent #add_keywords_form_clone .add_keywords {
  /* display: flex; */
  gap: 10px;
  margin-bottom: 17px;
}

.keyword_add_form .form .add_keywords_parent .add_keywords_form .add_keywords input,
.keyword_add_form .form .add_keywords_parent #add_keywords_form_clone .add_keywords input {
  height: 30px;
  width: 96%;
}

.keyword_add_form .form .add_keywords_parent .add_keywords_form .add_keywords .col,
.keyword_add_form .form .add_keywords_parent #add_keywords_form_clone .add_keywords .col {
  width: 30%;
  display: flex;
  gap: 6px;

}

.keyword_add_form .form .add_keywords_parent .add_keywords_form .add_keywords .col .label,
.keyword_add_form .form .add_keywords_parent #add_keywords_form_clone .add_keywords .col .label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.keyword_add_form .form .add_keywords_parent #add_keywords_form_clone .addkeywordremove {
  cursor: pointer;
}

.keyword_add_form .form .form_button {
  display: flex;
  gap: 35px;
  margin-bottom: 10px;
  justify-content: center;
}

.AddDataTableErr {
  text-align: center;
}

.keyword_add_form .form .form_error_msg {
  color: #d31313;
  font-weight: 800;
  margin-bottom: 10px;
  text-align: center;
}

.keyword_add_form .form .form_button .greenbutton {
  background-color: #084f0a;
  border: none;
  color: white;
  padding: 12px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  border-radius: 4px;
}

.keyword_add_form .form .form_button .greenbutton:hover {
  background: #084f0ad1;
}

.keyword_add_form .form .form_button .redbutton:hover {
  background-color: #d31313d1;
}

.keyword_add_form .form .form_button .redbutton {
  background-color: #d31313;
  border: none;
  color: white;
  padding: 12px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  border-radius: 4px;
}

.brand_list_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2) {
  display: block;
  padding-left: 135px;
}

.brand_list_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2) {
  display: block;
  padding-left: 135px;
}

.userdomain_all .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2) {
  display: block;
  padding-left: 0px !important;
}

.userdomain_all .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3) {
  display: block;
  padding-left: 100px !important;
}

.userdomain_all .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(5) {
  display: block;
  padding-left: 64px !important;
}

.search_volume_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2) {
  display: block;
  padding-left: 150px;
}

.search_volume_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3) {
  display: block;

}



.campapgin_settings_list_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3) {
  display: block;
  padding-left: 60px;
}

.brand_list_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(4) {

  padding-left: 45px;
}

.brand_list_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(5) {

  padding-left: 45px;
}

.brand_list_table .edit_icon svg {
  width: 25px;
  height: 16px;
  /* margin-left: 4px; */
  position: relative;
  /* top: 2px; */
}

.brand_list_table .trash_icon svg {
  width: 25px;
  height: 16px;
  /* margin-left: 4px; */
  position: relative;
  /* top: 2px; */
}

.tgbranded_term_heading,
.tgkeyword_heading {
  /* font-size: 17px; */
  padding-left: 60px;
  margin-top: 37px;

}

.keyword_edit_form_match_row {
  margin-top: 20px;
}

input.react-dropdown-select-input {
  color: rgb(0, 0, 0);
  /* font-size: 15px; */
}

.MuiPaper-root input.react-dropdown-select-input::placeholder {
  color: white !important;
}

.MuiPaper-root .css-1yc4zyy-DropdownHandleComponent svg {
  fill: #ffffff;
}

.google_bing_campagin_ul {
  list-style: none;
}

.google_bing_campagin_ul .child_ul {
  list-style: none;
  padding: 0px;
  padding-left: 31px;
  margin-top: 5px;


}

.google_bing_campagin_ul .child_ul.hidden {
  display: none;
}


.google_bing_campagin_ul .engines {
  display: flex;
  align-items: center;
}

.google_bing_campagin_ul .engines .child_ul_show {
  position: relative;
  top: 4px;
  left: 4px;

}

.google_bing_campagin_ul .engines .child_ul_show .show {
  display: block;

}

.google_bing_campagin_ul .engines .child_ul_show .hide {
  display: none;

}

.google_bing_campagin_ul .child_ul li {
  padding-left: 16px;
  text-align: initial;
}

.external_link_data_row #active {
  box-shadow: 0px 0px 7px 5px #ff601fd6;
}

.external_link_data_row .cols.ranking_summary_col:hover {
  box-shadow: 0px 0px 7px 5px #ff601fd6;
}

.css-1vuxth3-MuiBarElement-root {
  fill: #000000 !important;
}

.external_link_data_row .ranking_summary_col {
  cursor: pointer;
}

.rounded-dot {
  width: 20px;
  height: 20px;

  border-radius: 50%;
  display: inline-block;
}

.rounded-dot.orange {
  background-color: orange;
}

.rounded-dot.green {
  background-color: #4da042;
}

.rounded-dot.blue {
  background-color: #4de4f4;
}

.rounded-dot.yellow {
  background-color: #eff157;
}



.rounded-dot-col {
  display: flex;
  gap: 7px;
  margin-right: 10px;
  width: 500px;
}

.rounded-dot-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.prefered_url_external {
  display: flex;
  word-break: break-all;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #044792 !important;
}

.prefered_url_external.center {
  justify-content: center;
}

.prefered_url_external a {
  color: #044792;
  text-decoration: none;
}

.search_engine_row {
  display: flex;
  align-items: center;
}

.search_engine_row img {
  width: 25px;
  height: 25px;
}

.back_link_trend_data_row .cols {
  width: 30% !important;
}

.backlinks_summary_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  text-align: left;
}

.stacked-bar-graph .bardata.white {
  background: #ff601f;
}

.backlinks_research_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(1) {

  display: block !important;
  text-align: left !important;
  padding-left: 35px;


}

.backlinks_research_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(4) {

  display: block !important;
  text-align: left !important;
  /* padding-left: 70px; */



}

.backlinks_research_table .external_link_icon {
  width: 20px;
  height: 20px;
  left: 5px;
  position: relative;
  top: 4px;
  cursor: pointer;
}

.gain_drop_row {
  display: flex;
  justify-content: center;
}

.gain_drop_row.no_change_row svg {
  height: 13px;
  position: relative;
  top: 2px;

}

/* .data_table.internal_link_checker_table {
        overflow: auto;
    } */
.site_audit_dashboard_outer {
  padding-left: 20px;
}

.site_audit_dashboard_outer .card_row {
  display: flex;
  gap: 5%;
}

.site_audit_dashboard_outer .card_row .cols {
  width: 27%;
  background: #000000;
  border-radius: 6px;
  padding: 10px 20px 30px;
}

.site_audit_dashboard_outer .card_row .site_data_row {
  border-radius: 10px;
  /* border: 1px solid #ffffff; */
  padding: 10px 0px;
  /* background: #fe874e; */
  min-height: 145px;
}

.site_audit_dashboard_outer div h3 {
  color: #ff601f;
}

.site_audit_dashboard_outer .card_row .cols .heading {
  text-align: center;
  color: #fe874e;
  /* font-size: 25px;
        font-weight: 700; */
}

.site_audit_dashboard_outer .card_row .cols .versus {
  text-align: center;
  font-size: 14px;

}

.site_audit_dashboard_outer .card_row .cols .data {
  color: #fff;
  padding: 10px 20px;
  display: flex;
  border: 2px solid #f5f5f5;
  border-radius: 12px;
  margin-top: 12px;
}

.site_audit_dashboard_outer .card_row .cols .data .value {
  width: 20%;
}

.filter_dash_col p {
  position: relative;
}

.filter_dash_col span {
  position: absolute;
}

.error_plagarism_report.lightorange {
  color: #ff601f;
}

.drop_menu {
  position: absolute;
  top: 35px;
  background: #ffffff;
  padding: 10px;
  right: 1%;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #fe874e;
}

.top_icons_grp span.account_set_wrap svg {
  /* fill: #000000;
            height: 22px;
            width: 22px; */
  fill: #000000;
  height: 60px;
  width: 50px;

}


.drop_menu .menu:hover {
  color: #ff601f;
  text-decoration: none;
}

.drop_menu .menu:hover span.pass_img svg {
  fill: #ff601f;
}

.drop_menu .menu {
  fill: #0b2033;
  color: #0b2033;
  cursor: pointer;
  text-decoration: none;
  /* font-size: 15px; */
  padding-bottom: 5px;
}

.register_term_accept {
  color: #fff;
  /* font-size: 14px; */
}

.register_term_accept input {
  cursor: pointer;
  position: relative;
  top: 2px;

}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
  /* background-color: #fe874e !important; */
  border-radius: 0px;
}

#activetabj {
  z-index: 9;
  background: #ff601f !important;
  color: #fff !important;
}

#activetabj:hover {
  color: #fff !important;
}

.change_password_eye {
  position: relative;
}

.change_password_eye .toggle-password {
  top: 10px;
  cursor: pointer;
  /* right: 120px; */
  position: absolute;
  /* z-index: 999; */
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  color: white;
}

.data_table .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  display: none;
}

.prefered_url_external02 svg {
  position: relative;
  /* top: -2px; */

  top: 8px;

  fill: #044792;
}

.prefered_url_external02 span {
  color: #000000;
}

.prefered_url_external svg {
  position: relative;
  /* top: -2px; */

  /* top: 8px; */

  fill: #044792;
}

.buttonView.edit_icon,
.buttonView.trash_icon {
  width: 75px !important;
}

.below_note_ranking {
  padding: 5px;
}

.below_note_ranking.icon {
  display: flex;
}

.below_note_ranking.icon svg {
  position: relative;
  top: 6px;
}

.below_note_ranking.icon .green {
  color: #008900;
}

.below_note_ranking.icon .rose {
  color: rgb(255 0 0);
}

.below_note_ranking.icon .orange {
  color: orange;
}

.below_note_ranking.icon .orange svg {
  width: 14px;
  padding-left: 3px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 50% !important;
  height: unset !important;
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  overflow: auto !important;
}

.css-wmy1p7-ReactDropdownSelect:hover,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  border-color: #f9e3bb;
}

.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom.css-13746x7-DropDown.e1qjn9k92 {
  height: 160px;
}

.css-u6gnge-DropDown {
  width: unset;
  height: 200px;
}

.css-wmy1p7-ReactDropdownSelect {
  min-width: 200px;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root button:hover {
  background: #fe874e !important;
}

button.buttonView:hover {
  background: #00000005;
  color: #fe874e;
  box-shadow: 0px 0px 2px 2px #fe874e;
}

/* .competitor_ranking_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(1) {
    display: block;
    padding-left: 20px;
} */

/* .competitor_ranking_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(6) {
    display: block;
    padding-left: 45px;
} */


.missed_oppurtunites_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2) {
  /* display: block; */
  padding-left: 30px;
}

.missed_oppurtunites_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(6) {
  display: block;
  padding-left: 30px;
}

.missed_oppurtunites_table .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor:nth-of-type(9) {
  padding-left: 39px;
  display: block;

}

.missed_oppurtunites_table .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor:nth-of-type(10) {
  padding-left: 39px;
  display: block;

}

.search_location_with_tool,
.domain_with_tool {
  display: flex;
  align-items: center;
}

/* .search_location_with_tool div {
    width: 99%;
} */

/* .search_location_with_tool svg {
.Logo_wrap img {
    display: block;
    width: 70px;
}
.drop-organicsearch .css-1nmdiq5-menu{
    over-flow:visible !important;
    }
  
width: 17px !important;
position: relative;
top: 7px;
left: 6px;
}

.search_key_wrap.searchvolume_key_wrap .plagarism_analyzer_row .input_col {
    width: 42%  !important;
} */
.search_location_with_tool .svg_search,
.domain_with_tool .svg_search {

  width: 40px !important;
  /* position: relative; */
  top: 7px;
  left: -3px;
  color: #ff601f;

}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.svg_search.css-i4bv87-MuiSvgIcon-root+div {
  width: 99%;
}

.search_key_wrap.searchvolume_key_wrap .plagarism_analyzer_row .input_col {
  width: 41%;
}

.moda_pop_search_volume_svg {
  position: relative;
  top: 6px;
}

.tabs_outer_wrap_tab_btm_rem .css-1gsv261 {
  border-bottom: 0px solid;
}

.react-dropdown-select-content span,
.cnt_audit_outer_wrap.plagiarisam-scan-detail {
  color: #0b2033 !important;
  margin-top: 15px;
}

.plagiarism_scan_ifram_row {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.profile_update_button {
  padding: 9px 15px !important;
  color: #ffffff;
  background: #ff601f;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 10px;

}

.profile_update_label {
  margin-right: 5px;
}

.profile_email_verify_outer_wrap {
  padding-bottom: 20px !important;
}

.plagarism_analyzer_row_input_field {
  padding: 0px 2px !important;
}

.new_links_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3) {
  display: block;
  padding-left: 250px;
}

.domain_input_row {
  margin-bottom: 0px !important;

}

.domain_input_row_note {
  padding-left: 5% !important;
  margin-bottom: 10px;
  color: #ffae1f;
}

.row.addbranded_terms {
  display: flex;
  justify-content: center;
  align-items: end;
}

.greenbutton {
  background-color: #084f0a;
  border: none;
  color: white;
  padding: 12px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  border-radius: 4px;
}

.redbutton {
  background-color: #d31313;
  border: none;
  color: white;
  padding: 12px 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  border-radius: 4px;
}

.form-inner_brands {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.form-inner_brands .col {
  display: flex;
  gap: 78px;
}

.col label.form-label {
  /* vertical-align: sub; */
  padding-top: 5px;
}

.colhead.brandhead {
  display: block;
  gap: 10px;
}

.colhead.inputhead {
  display: flex;
  align-items: center;
}

.campaignnextbutton {
  border-radius: 10px;
  /* font-size: 20px; */
  position: relative;
  color: #fff;
  width: 14%;
  padding: 20px 20px;
  background: #FFAE1F;
  margin: 0em 1em;
}

.campaigncancelbutton {
  border-radius: 10px;
  /* font-size: 20px; */
  position: relative;
  color: #fff;
  width: 14%;
  padding: 20px 20px;
  background: #ff0808;
  margin: 0em 1em;
}

.err {
  /* color:  #FFAE1F; */
  color: #ff0000;
}

.brand_term_add_form .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 50% !important;

}

.brand_term_add_form.competitor_test_filter .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root,
.brand_term_add_form .form-inner_brands {
  width: 100%;
}

.brand_term_add_form i.fa.fa-times.brandedtermremove {
  padding: 0px 4px;
}

.brand_term_add_form .cnt_hdr_blw_wrap {
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.brand_term_add_form .cnt_hdr_blw_wrap .eighty_per {
  width: 80%;
}

.brand_term_add_form .row.addbranded_terms {
  /* margin-bottom: 10px !important;
    margin-top: 10px !important; */
  margin-left: 8px;
}

.brand_term_add_form .branded_term {
  padding: 4px 0px;
}

.search_data_word .col {
  min-width: 35% !important;
  max-width: 35% !important;
  justify-content: space-between;
}

.add_keywords select.form-select,
.keyword_add_form .form .add_keywords_parent .add_keywords_form .add_keywords .col .input {
  height: 35px;
  width: 60%;
}

.add_keywords {
  display: flex;
  flex-direction: column;
}

.search_data_word {
  gap: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 10px;
  justify-content: center;
}

.error_plagarism_report_target {
  text-align: center;
  color: #ffad1b;
}

.BrandMatchPlus {
  position: relative;
  top: 3px;
  left: 3px;
  color: #ff601f;
  margin-left: 20px;
}

p.linkdata_value {
  color: #044792;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-cellContent {
  color: #0b2033;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor a {
  text-decoration: none;
}

.add_keywords option {
  background: #fff;
}



.listdropdown {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  /* margin-left: 30px; */
  /* padding: 0px 0px; */
}

.inner_listdropdown {
  margin-left: 30px;
}

.organic_search_data {
  margin-top: 40px;
  margin-bottom: 20px;
}

/* .organic_table_inner .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
    min-height: 100px!important;
    max-height: 100px!important;
    line-height: 6px!important;
  } */
.dymanic_head_outer_span {
  color: black;
}

.subscription_wrap .pricing_heading {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.subscription_wrap .pricing_heading span {
  /* font-size: 14px; */
  color: white;
  text-transform: capitalize;
  background: #ff601f;
  border: 0px;
  padding: 11px 15px;
  border-radius: 9px;
}

.subscription_wrap .plan_description {
  display: flex;
  justify-content: center;
}

.subscription_wrap .plan_description p {
  /* font-size: 30px; */
  color: #0b2033;
}

.subscription_wrap .site_cnt_hdr_blw_wrap.plan_switch_button {
  height: 100px;
  text-align: center;
  margin-top: 0px;
}

.subscription_wrap .plan_switch_button {
  display: flex;
  justify-content: center;
}

.subscription_wrap .plan_switch_button .row {

  display: flex;
  justify-content: center;
  background: #ff601f;
  padding: 14px;
}

.subscription_wrap .plan_switch_button .row .columns.active {
  background-color: white;
  color: #0b2033;
}

.subscription_wrap .plan_switch_button .row .columns {
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  color: white;
}

.subscription_wrap .plan_switch_button .row .columns:hover {
  background-color: #ffffff7d;
  color: #0b2033;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}

.subscription_wrap .plans_list .container {
  display: flex;
  margin: 14px auto;
  border-radius: 5px;
  justify-content: center;
}



.subscription_wrap .plans_list .container>div {
  margin-left: 15px;
  margin-right: 15px;
  background-color: white;
  width: 29%;
  float: left;
  /* border-right: 1px solid #ccc; */
  position: relative;
  border-radius: 5px;
  transition: background-color 2s ease;
}

.subscription_wrap .plans_list .container>div img {
  width: 200px;
  display: block;
  margin: auto;
}

.subscription_wrap .plans_list .container>div p {
  text-align: center;
  font-weight: bolder;
  letter-spacing: 1px
}

.subscription_wrap .plans_list .container>div .list_descriptions {
  text-align: center;
}

.subscription_wrap .container .account::before {
  content: "1";
  color: #555;
  margin-right: 3px;
}

.subscription_wrap .container .price,
.type {
  color: #635858;
  /* font-size:26px; */
}

.subscription_wrap .plans_list .container>div:hover,
.subscription_wrap .plans_list .container>div.lists.active {
  box-shadow: 0px 0px 5px 0px;
  background: linear-gradient(to left, #ff601f, #ff601f);
  color: #fff !important;
}

.subscription_wrap .plans_list .container>div:hover div p,
.subscription_wrap .plans_list .container>div.lists.active div p {
  color: #fff;
}

.subscription_wrap .plans_list .container>div:hover .choose_button_wrap button,
.subscription_wrap .plans_list .container>div.lists.active .choose_button_wrap button {
  background: #fff;
  color: #0b2033;
  box-shadow: 0px 0px 2px 2px #a4a6a8;
}

.subscription_wrap .plans_list .container>div:hover svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}

.subscription_wrap svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #fe874e;
}

.subscription_wrap .container .price::before {

  color: #555;
  margin-right: 3px;
}

.subscription_wrap .container .period {
  /* font-size: 15px; */
  font-weight: 500;
}

.subscription_wrap .plans_list .container .first_row {
  padding-bottom: 50px;
  /* border: 2px solid #ff601f; */
}

.subscription_wrap .plans_list .container .first_row .plan_head {
  border-bottom: 1px solid #0000002e
}

.subscription_wrap .plans_list .choose_button_wrap {
  width: 100%;
  display: flex;
  /* margin: 0 auto; */
  position: absolute;
  bottom: 10px;
  justify-content: center;
  margin-left: 6%;
}

.subscription_wrap .plans_list .choose_button_wrap button {
  padding: 10px;
  border-radius: 5px;
  background: linear-gradient(to left, #f75e20, #ff9e00);
  color: white;
}

.subscription_wrap .plans_list .choose_button_wrap button:hover {
  background: linear-gradient(to right, #f75e20, #ff9e00);
}

.subscription_wrap .plans_list .choose_button_wrap button.active:hover {
  background: linear-gradient(to bottom, #ff8c00, #ff0b5a);
}

.subscription_wrap .plan_detail_wrap .lists {
  width: 90% !important;
}

.subscription_wrap .plan_detail_wrap .close_icon {
  color: orange;
  display: flex;
  justify-content: end;
  padding: 5px;
}


.plan_details_table_wrap table {
  border-collapse: collapse;
  width: 100%;
}

.plan_details_table_wrap tr th {
  /* font-size: 18px; */
  padding: 12px;
  border: 1px solid #eeeeee;
  text-align: left;
  background-color: rgba(217, 221, 146, 0.2);
}

.plan_details_table_wrap tr td {
  border: 1px solid #eeeeee;
  text-align: center;
  width: 50%;
  padding: 10px;
}

.plan_details_table_wrap .title {
  text-align: center;
  /* font-size: 26px; */
  margin-bottom: 30px;
}

.plan_details_table_wrap .plan_yes {
  color: green;
}

.plan_details_table_wrap .plan_no {
  color: red;
}

.paypal_subs_button_wrap {
  display: flex;
  justify-content: center;
}

.paypal_subs_button_wrap .paypal_subs_button {
  width: 60%;
}

.subscription_wrap .free_plan_wrap {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  background-color: white;
  margin: 20px;
}

.subscription_wrap .free_plan_wrap .free_plan_info {
  width: 90%;
  display: flex;
  justify-content: center;
  background: white;
  padding: 20px;
  border-radius: 6px;
}

.subscription_wrap .free_plan_wrap .free_plan_info .info {
  /* font-size: 26px; */
}

.subscription_wrap .free_plan_wrap .free_plan_info .view_button {
  display: flex;
  justify-content: center;
}

.subscription_wrap .free_plan_wrap .free_plan_info .view_button button {
  padding: 13px;
  background: linear-gradient(to left, #ff8c00, #ffcc00);
  border-radius: 5px;
  margin-top: 15px;
  color: white;
}

.subscription_wrap .free_plan_wrap .close_free {
  position: relative;
  /* right: 28px; */
  top: 6px;
  color: #ff8c00;

}

.subscription_wrap .plan_ul_list_wrap {
  display: flex;
  justify-content: center;
}

.subscription_wrap .plan_ul_list_wrap .plan_ul_list {
  padding: 0px;
}

.subscription_wrap .plan_ul_list_wrap .plan_ul_list li {
  padding: 10px;
  color: gray;
}

.subscription_wrap .plan_ul_list_wrap p {
  color: gray;
  font-weight: 500 !important;
}

.daterangepicker_wrap {
  display: flex;
  margin-top: 20px;
  gap: 10px;

  margin-left: 48px;

}

.daterangepicker_wrap .react-datepicker__input-container input {
  padding: 10px;
}

.wrap_trans_back .daterangepicker_wrap .react-datepicker__input-container input {
  border-radius: 5px;
  border: none;
}

.wrap_trans_back select {
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
}

.daterangepicker_wrap .daterangepicker_wrap_submit {
  padding: 8px 11px 10px 13px;
  top: 0px;
  margin-left: 10px;
}

button.site_rn_rpt_btn.daterangepicker_wrap_submit:hover {
  background: #ff601f !important;
  color: #fff;
  box-shadow: none;
}

.brand_google_analytics_table .MuiDataGrid-overlayWrapper.css-ql19fo-MuiDataGrid-overlayWrapper {
  position: relative;
  z-index: -9;
}


.brand_google_analytics_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2) {
  display: block;
  padding-left: 0px !important;
}



.site_blw_wrap.organic_search_data {
  position: relative;
  z-index: 9;
}

.css-1nmdiq5-menu {
  color: #000;
}

.organic_table_inner .css-1hsc7hf-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  white-space: break-spaces;
  overflow: visible;
  position: relative;
  line-height: 20px;
}

.organic_table_inner .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  min-height: 100px !important;
  line-height: 6px !important;
}

#message-area {
  display: flex;
  justify-content: center;
  padding: 30px;
}

#message-area .message_wrap {
  background: #80808054;
  border-radius: 5px;
}

.alert-error {
  color: #a94442 !important;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-info {
  color: #31708f !important;
  background-color: #d9edf7;
  border-color: #bce8f1;
}







#message-area .alert {
  padding: 24px;
  color: white;
}

.brand_container g.MuiChartsLegend-root.MuiChartsLegend-column.css-1u0lry5-MuiChartsLegend-root {
  transform: translateX(-120px);
}

.brand_container text {
  color: #0b2033 !important;
  fill: #0b2033 !important;
}

.brand_container select option {
  color: #0b2033 !important;
}

.site_regress_chart_info.organic_search_data.organic_class_inner {
  margin-top: 15px;
  position: relative;
}

.google_branded_row.external_link_data_row .cols {
  border: 1px solid white !important;
  color: white !important;
}


.brand_container button.site_rn_rpt_btn.active {
  z-index: 0 !important;
}

.brand_container .table_error_message {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  color: #ff0000;
  /* font-size: 25px; */
}

.brand_container .site_cnt_hdr_blw_wrap {
  margin-top: 0px !important;
}

/* .google_area_chart_wrap{
    display: flex;
    justify-content: center;
    margin-top: 25px;
  } */
/* .google_area_chart_wrap .row{
    width: 90%;
    height: 300px;
  }
  .google_area_chart_wrap .row.pie_chart_row{
    width: 35%;
  }
  .google_area_chart_wrap.brand_mention .row.pie_chart_row{
    width: 35%;
  } */
.dashboard_traffic_widget {
  color: #ff6120;
  padding-left: 20px;
  margin-bottom: 20px;
}

.MuiDataGrid-selectedRowCount.css-de9k3v-MuiDataGrid-selectedRowCount {
  color: #0b2033;
}

.keyword_add_form .form {
  color: #0b2033;
  background: #fff;
  padding: 20px;
}

.google_area_chart_wrap text,
.google_area_chart_wrap .apexcharts-legend-text {
  color: #0B2033 !important;
  fill: #0B2033 !important;
}

.google_area_chart_wrap .apexcharts-tooltip.apexcharts-theme-light {
  color: black;
}

.site_cnt_hdr_blw_wrap.dashboard_traffic_switch {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  /* gap: 50px; */
}

.filter_icon_dashboard.top_5 {
  margin-bottom: 20px;
}

div.error_org_msg {
  text-align: center;
  margin-top: 50px;
  /* font-size: 29px; */
}

.drop-organicsearch .css-1nmdiq5-menu {
  position: relative;
  width: 100%;
}

.Logo_wrap img.Siteicon {
  width: 30px;
  background: #ffffff;
  border-radius: 10px;
}

.Logo_wrap img.Siteimage {
  width: 120px;
  background: #ffffff;
  border-radius: 10px;
}

@keyframes fadeInImage {
  from {
    opacity: 0;
    width: 0;
  }

  to {
    opacity: 1;
  }
}

.metric_notes_row {
  display: flex;

  padding: 20px;
  margin-bottom: 20px;
}

.metric_notes_row .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
}

.google_traffic_error {
  display: flex;
  justify-content: center;
  padding: 72px;
  background: #fe874e;
  border-radius: 9px;
  width: 88%;
  color: #000000;
  /* font-size: 16px; */
}

/* .row.pie_chart_row{
        width: 36%;
    } */
.wrap_trans_back {
  /* margin-top: 20px;
  padding-top: 10px; */
  padding-bottom: 10px;
  background: url(../../../src/pages/NavBar/Asset/asset_table.png ) !important
}

.button_organic_div {
  margin-left: auto;
  float: right;
  /* padding: 17px 19px; */
}

button.button_organic_button {
  /* width: 50px; */
  /* height: 30px; */
  position: relative;
  z-index: 99;
  padding: 12px 20px;
  /* margin-right: 30px; */
  background: #000000;
  border-radius: 7px;
  color: white;
}

button.button_organic_button:hover {
  background-color: #ff601f;
}

.custom_pie_tool_tip {
  background-color: white;
  color: black;
  padding: 10px;
}


@keyframes fadeOutImage {
  from {
    opacity: 1;
    width: 100px;
  }

  to {
    opacity: 0;
    width: 0;
  }
}

.Logo_wrap img.Siteicon,
.Logo_wrap img.Siteimage {
  display: block;
  animation: fadeInImage 1s ease;
  -webkit-animation: fadeInImage 1s ease;
  -moz-animation: fadeInImage 1s ease;
}

.Logo_wrap img.Siteimage.Hide_meimg,
.Logo_wrap img.Siteicon.hideme {
  display: none;
  animation: fadeOutImage 2.5s ease;
  -webkit-animation: fadeOutImage 2.5s ease;
  -moz-animation: fadeOutImage 2.5s ease;
}

.Logo_wrap {
  padding: 10px 0px 20px;
}

.Logo_wrap img {
  padding: 10px;
  margin: 0 auto;
}

.css-ptiqhd-MuiSvgIcon-root {
  fill: #0b2033 !important;
}

.css-204u17-MuiDataGrid-main .css-ptiqhd-MuiSvgIcon-root {
  fill: #ffffff !important;
}

.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter input,
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter input::placeholder {
  color: #0b2033;
}

.cnt_audit_outer_wrap .cnt_hdr_top_wrap,
.site_blw_wrap {
  box-shadow: 0px 6px 6px #0000004D;
}

/* .MuiDataGrid-virtualScrollerRenderZone.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
        width: 1000px;
    } */
.nav_orange_icons {

  transition: all 0.3s ease;
}

.css-1yc4zyy-DropdownHandleComponent svg {
  fill: #0b2033;
}

.plagarism_analyzer_row .css-i4bv87-MuiSvgIcon-root {
  fill: #fe874e;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  /* font-weight: 500;
        font-size: 15px; */
  font-family: 'Poppins', sans-serif;
  /* font-size: 15px; */
  font-weight: 400;
  /* margin: 10px 0 10px 0; */
}

.site_cnt_hdr_blw_wrap button:hover {
  z-index: 99;
  background: #ff601f !important;
  color: #fff;
}

.siteaudit_chart_outer_container {
  display: flex;
  justify-content: center;
}

.prefered_url_external:hover,
p.linkdata_value:hover,
.MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle:hover a,
.prefered_url_external:hover a {
  color: #044792;
}

.site_cnt_hdr_blw_wrap a {
  text-decoration: none;
}

.prefered_url_external:hover svg,
.MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle:hover svg {
  fill: #044792;
}

.MuiDataGrid-selectedRowCount {
  color: #0b2033;
}

.dashboard_traffic_widget .site_cnt_hdr_blw_wrap button:hover {
  background: #ff601f !important;
}

.below_note_ranking.icon {
  display: flex;
  /* align-items: flex-end; */
  /* background: #fff; */
  padding-bottom: 15px;
  gap: 10px;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap button.run_rpt_btn.rn_rpt_btn:hover {
  background: #000000 !important;
  color: #fff;
  padding-right: 10px;
}

.cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn:hover {
  /* background: #fe874e !important; */
  color: #fff;
}

input[type=text]:focus {
  outline: 0px solid #ff5300;
  /* box-shadow: 0px 0px 3px 2px #ffffff; */
}


.css-1dimb5e-singleValue{
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(51, 51, 51);
    margin-left: 20px !important;
    margin-right: 2px;
    box-sizing: border-box;
}

.css-1jqq78o-placeholder {
  grid-area: 1 / 1 / 2 / 3;
  color: rgb(128, 128, 128);
  margin-left: 20px !important;
  margin-right: 2px;
  box-sizing: border-box;
}


.inactive_card_info svg {
  color: orange;
  /* font-size: 40px; */
}

.inactive_card_info {

  padding-top: 35px !important;
  padding-bottom: 75px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.plan_eso_logo_wrap {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: white;

}

.plan_eso_logo_wrap .plan_eso_logo_row {
  width: 30%;
}

.plan_eso_logo_wrap .plan_eso_logo_row img {
  width: 100%;
}


html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden ; */
}

:root {
  --font-size: 0.8em;
  --bg1: #f5f8f7;
  --blue: #3498db;
  --green: #2ecc71;
  --purple: #9b59b6;
  --gold: #f1c40f;
  --red: #e74c3c;
  --orange: #e67e22;
  --shadow1: 0 2px 4px #000 26, 0 3px 6px #000 1f;
  --shadow2: 0 2px 6px #000 44, 0 4px 7px #000 22;
}

/* .error_gwt main {
	 padding: 2vw;
} */
.error_gwt ul.infoGraphic {
  padding: 0px;
  margin: 0px;
  font-size: var(--font-size);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.error_gwt ul.infoGraphic li {
  position: relative;
  width: 100%;
  max-width: 25em;
  background: var(--bg1);
  border-radius: 0.5em;
  padding: 0.5em;
  z-index: 1;
  transition: all 0.2s;
  cursor: pointer;
}

.error_gwt ul.infoGraphic li .numberWrap {
  position: absolute;
}

.error_gwt ul.infoGraphic li .number {
  font-family: "maven pro", sans-serif;
  /* font-size: 13em; */
  font-weight: 900;
  width: 0.9em;
  text-align: center;
}

.error_gwt ul.infoGraphic li .number.fontColor1 {
  color: var(--blue);
}

.error_gwt ul.infoGraphic li .number.fontColor2 {
  color: var(--green);
}

.error_gwt ul.infoGraphic li .number.fontColor3 {
  color: var(--purple);
}

.error_gwt ul.infoGraphic li .number.fontColor4 {
  color: var(--gold);
}

.error_gwt ul.infoGraphic li .number.fontColor5 {
  color: var(--red);
}

.error_gwt ul.infoGraphic li .number.fontColor6 {
  color: var(--orange);
}

.error_gwt ul.infoGraphic li .coverWrap {
  transform: rotate(130deg);
  position: absolute;
  width: 18em;
  height: 15em;
  left: -3em;
  top: -1em;
}

.error_gwt ul.infoGraphic li .coverWrap .numberCover {
  position: absolute;
  background: var(--bg1);
  width: 18em;
  height: 6em;
  border-radius: 50% 50% 0 0;
  border-bottom: 3px solid #f5f8f7;
  transition: all 0.4s;
}

.error_gwt ul.infoGraphic li .coverWrap .numberCover::before {
  position: absolute;
  content: "";
  bottom: 5px;
  left: 4em;
  right: 4em;
  top: 5em;
  box-shadow: 0 0 30px 17px #486685 77;
  border-radius: 100px / 10px;
  z-index: -1;
}

.error_gwt ul.infoGraphic li .coverWrap .numberCover::after {
  position: absolute;
  bottom: 0;
  content: "";
  left: -10%;
  width: 120%;
  height: 150%;
  background: radial-gradient(at bottom, #486685 33, transparent, transparent);
  z-index: 1;
}

.error_gwt ul.infoGraphic li .content {
  margin: 8em 3em 1em 7em;
  position: relative;
}

.error_gwt ul.infoGraphic li .content h2 {
  /* font-size: 1.7em; */
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.error_gwt ul.infoGraphic li .content p {
  line-height: 1.5em;
}

.error_gwt ul.infoGraphic li:hover .coverWrap .numberCover {
  border-radius: 100%;
}

.error_gwt .icon {
  position: absolute;
  /* font-size: 2rem; */
  text-align: center;
  top: -5.3em;
  left: 50%;
  transform: translatex(-50%);
}

.error_gwt .icon:before {
  color: #666;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.error_gwt .iconCodepen:before {
  content: url('../../Icons/google-symbol.png');
}

.error_gwt .iconSocial:before {
  content: url('../../Icons/gear.png');
  ;
}

.error_gwt .iconAdmin:before {
  content: url('../../Icons/admin-icon.png');
  ;
}

.error_gwt .iconManagement:before {
  content: url('../../Icons/management.png');
  ;
}

.error_gwt .iconAddUser:before {
  content: url('../../Icons/add-user.png');
  ;
}

.error_gwt .iconAddEmail:before {
  content: url('../../Icons/letter.png');
  ;
}

.error_gwt .iconAddButton:before {
  content: url('../../Icons/add-button.png');
  ;
}

.error_gwt .iconConfirm:before {
  content: url('../../Icons/confirm.png');
  ;
}

.error_gwt .iconProvider:before {
  content: url('../../Icons/provider.png');
  ;
}




.error_gwt .iconRecipient:before {
  content: url('../../Icons/recipient.png');
  ;
}

.error_gwt .iconAirplane:before {
  content: url('../../Icons/user.png');
  ;
}

.error_gwt .iconMap:before {
  content: url('../../Icons/gmail.png');
}

.error_gwt .iconBulb:before {
  content: url('../../Icons/permission.png');
}

.error_gwt .iconPeace:before {
  content: url('../../Icons/refresh-button.png');
}

.error_gwt {
  position: relative;
  color: black !important;
  z-index: 0;
}

.error_gwt .numberWrap {
  display: none;
}

.error_gwt li {
  list-style-type: none;
}

.error_gwt_notice {
  display: flex;
  justify-content: center;
  color: white;
  background: #ffa500;
  width: 100%;
}

.error_gwt_notice_Outer {
  margin: 0 auto;
  max-width: 81%;
  background: #f5f8f7;
  margin-bottom: 100px;
  margin-top: 30px;
}

.error_gwt_notice .row {
  background: orange;
  padding: 20px;
  width: 80%;
}

.error_gwt_notice .row .icon {
  display: flex;
  justify-content: center;
}

.error_gwt_notice .row .icon svg {
  /* font-size: 40px; */
}

.error_gwt_notice .row .msg {
  display: flex;
  justify-content: center;
  /* font-size: 20px; */
}

.below_note_ranking {
  color: red;
}

input[type='radio']:after {
  background-color: #d1d3d1 !important;
}

.error_gwt_notice_Outer .error_gwt .heading_p {
  display: flex;
  justify-content: center;
}

input[type='radio']:checked:after {
  background-color: #ffa500 !important;
}

.brand_mentions_form {
  display: flex;
  flex-direction: column;

  /* color: white; */

}

.brand_mentions_form label {
  /* color: white; */
  margin-right: 12px;
}

.brand_mentions_form input {
  padding: 8px;
  border-radius: 5px;
  /* border-style: none;
    width: 95%; */
  border: solid 1px #ff601f;
}

.brand_mentions_form .run_rpt_btn.rn_rpt_btn {
  padding: 10px 15px !important;
}

.brand_mentions_form select {
  padding: 6px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.skip_button_payment_wrap button {
  padding: 13px;
  background: linear-gradient(to left, #ff8c00, #ffcc00);
  border-radius: 5px;
  margin-top: 15px;
  color: white;
  width: 25%;
}

.skip_button_payment_wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 21px;
}

.brand_mention_err {
  display: flex;
  justify-content: center;
}

.data_table .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
  background: #ffffff !important;
  box-shadow: 0px 0px 2px 2px #fe874e;
  border-radius: 10px;
  padding: 0px 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-top: 30px;
}

.data_table .css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before,
.data_table .css-v4u5dn-MuiInputBase-root-MuiInput-root:after,
.data_table .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter .MuiInput-underline:before {
  border-bottom: 0px solid #0b2033;
}

.over_all_container.error_page {
  background-color: #fff
}

.orang_txt {
  color: #ff601f;
  padding-left: 20px;
}

.keyword_add_form .form .add_keywords_parent .add_keywords_form .add_keywords .col .label,
.keyword_add_form .form .form-label {
  font-weight: 700;
  /* font-size: 16px; */
}

.add_keywords_form .add_keywords {
  font-weight: 700;
  /* font-size: 20px; */
}

.MuiDataGrid-cellContent {
  text-align: center;
}

.brand_mention_pie_row {
  display: flex;
  justify-content: center;
}

.drop_menu .menu span.pass_img {
  width: 10%;
  padding-right: 10px;
}

.drop_menu .menu {
  display: flex;
  align-items: flex-start;
  margin: 3px 6px;
}

.MuiDataGrid-overlayWrapper .MuiDataGrid-overlayWrapperInner .css-b5h07o {
  margin: 15px 0px;
}

.plagiarisam_missing_waring_wrap.plagiarisam_chart_outer text.text {
  fill: #0b2033;
}

.cnt_hdr_blw_wrap.add_keyword_button_wrap.domain_wrap {
  margin: 25px 0px 0px;
}

.add_key_txt {
  text-align: center;
  font-weight: 700;
  background: #fe874e;
  display: inline-block;
  padding: 10px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* .tab_otr_outer.lgn_container {
    background: #ffffff;
} */




.show_in_progress_brand_mention .wrapperAlert {
  width: 90%;
  height: 400px;
  overflow: hidden;
  border-radius: 12px;
  border: thin solid #ddd;
}

.show_in_progress_brand_mention .topHalf {
  width: 100%;
  color: white;
  overflow: hidden;
  min-height: 250px;
  position: relative;
  padding: 40px 0;
  background: transparent;

}

.show_in_progress_brand_mention .topHalf img {
  width: 200px;
  height: 200px;
}

.show_in_progress_brand_mention .topHalf p {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.show_in_progress_brand_mention svg {
  fill: white;
}

.show_in_progress_brand_mention .topHalf h1 {
  /* font-size: 2.25rem; */
  display: flex;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.15rem;
  text-shadow: 0 2px rgba(128, 128, 128, 0.6);
  color: black;
}

/* Original Author of Bubbles Animation -- https://codepen.io/Lewitje/pen/BNNJjo */
.show_in_progress_brand_mention .bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.show_in_progress_brand_mention li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  /* fade(green, 75%);*/
  bottom: -160px;

  -webkit-animation: square 20s infinite;
  animation: square 20s infinite;

  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.show_in_progress_brand_mention li:nth-child(1) {
  left: 10%;
}

.show_in_progress_brand_mention li:nth-child(2) {
  left: 20%;

  width: 80px;
  height: 80px;

  animation-delay: 2s;
  animation-duration: 17s;
}

.show_in_progress_brand_mention li:nth-child(3) {
  left: 25%;
  animation-delay: 4s;
}

.show_in_progress_brand_mention li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;

  animation-duration: 22s;

  background-color: rgba(white, 0.3);
  /* fade(white, 25%); */
}

.show_in_progress_brand_mention li:nth-child(5) {
  left: 70%;
}

.show_in_progress_brand_mention li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;

  animation-delay: 3s;
  background-color: rgba(white, 0.2);
  /* fade(white, 20%); */
}

.show_in_progress_brand_mention li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;

  animation-delay: 7s;
}

.show_in_progress_brand_mention li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;

  animation-delay: 15s;
  animation-duration: 40s;
}

.show_in_progress_brand_mention li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;

  animation-delay: 2s;
  animation-duration: 40s;
  background-color: rgba(white, 0.3);
  /*fade(white, 30%);*/
}

.show_in_progress_brand_mention li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;

  animation-delay: 11s;
}

@-webkit-keyframes square {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-500px) rotate(600deg);
  }
}

@keyframes square {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-500px) rotate(600deg);
  }
}

.show_in_progress_brand_mention .bottomHalf {
  align-items: center;
  padding: 35px;
}

.show_in_progress_brand_mention .bottomHalf p {
  font-weight: 500;
  /* font-size: 1.05rem; */
  margin-bottom: 20px;
}

.show_in_progress_brand_mention button {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 12px;
  padding: 10px 18px;
  background-color: #019871;
  text-shadow: 0 1px rgba(128, 128, 128, 0.75);
}

.show_in_progress_brand_mention button:hover {
  background-color: #85ddbf;
}

.show_in_progress_brand_mention {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.sitemap_generator .type_of_settings {
  display: flex;
  color: black;
  gap: 15px;
}

.sitemap_generator .type_of_settings .typecol {
  background-color: #ff601f;
  padding: 15px;

  border-radius: 6px;
  padding-bottom: 21px;
}

.sitemap_generator .type_of_settings .typecol svg {
  width: 15px;
  position: relative;
  top: 6px;
}

.sitemap_generator .settings_form_row {
  margin-left: 42px;
  border: 1px solid #ff601f;
  padding: 50px;
}

.sitemap_generator .type_of_settings .typecol input[type='checkbox'] {
  cursor: pointer;
}

.sitemap_generator .settings_form .form_field_row {
  display: flex;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sitemap_generator .settings_form .form_field_row .label_col {
  width: 14%;
}

.sitemap_generator .settings_form .form_field_row svg {
  width: 15px;
  position: relative;
  top: 6px;
}

.sitemap_generator .settings_form .form_field_row select {
  padding: 6px;
  border-radius: 5px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.sitemap_generator .settings_form .form_field_row .field_col .two_inputs {
  display: flex;
  gap: 10px;
}

.sitemap_generator .settings_form .form_field_row .field_col .two_inputs input {
  padding: 4px;
  border: none;
  border-radius: 4px;
  border: 1px solid #ff601f;
}

.sitemap_generator .settings_form .form_field_row .field_col {

  position: relative;
  top: 8px;
  margin-left: 80px;
}


.sitemap_generator .settings_form .form_field_row .field_col textarea {
  border: 1px solid #00000061;
  border-radius: 4px;
}

.sitemap_generator .in_table_progress_icon {
  width: 38px;
  height: 40px;
}

.sitemap_generator .in_table_progress_icon_wrap {
  display: flex;
  align-items: center;
}

.post__content #results>ol>li {
  color: #0b2033;
}

.MuiDataGrid-main svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root {
  fill: #ffffff !important;
}

.MuiDataGrid-main .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover {
  /* background-color: #000000 !important; */
  background-color: unset !important;

}

.MuiDataGrid-main .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  /* background-color: #000000c9 !important; */
  width: 20px;
  height: 20px;
}

.run_report_now_modal .main-container {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.run_report_now_modal .main-container h2 {
  margin: 0 0 80px 0;
  color: #555;
  /* font-size: 30px; */
  font-weight: 300;
}

.run_report_now_modal .radio-buttons {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.run_report_now_modal .custom-radio input {
  display: none;
}

.run_report_now_modal .radio-btn {
  margin: 10px;
  width: 150px;
  height: 170px;
  border: 3px solid transparent;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 20px #c3c3c367;
  cursor: pointer;
}

.run_report_now_modal .radio-btn>i {
  color: #ffffff;
  background-color: #FFDAE9;
  /* font-size: 20px; */
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%) scale(2);
  border-radius: 50px;
  padding: 3px;
  transition: 0.5s;
  pointer-events: none;
  opacity: 0;
}

.run_report_now_modal .radio-btn .hobbies-icon {
  width: 100px;
  height: 120px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.run_report_now_modal .radio-btn .hobbies-icon img {
  display: block;
  width: 100%;
  margin-bottom: 20px;

}

.run_report_now_modal .radio-btn .hobbies-icon i {
  color: #FFDAE9;
  line-height: 80px;
  /* font-size: 60px; */
}

.run_report_now_modal .radio-btn .hobbies-icon h3 {
  /* color: #fff; */
  /* font-size: 18px; */
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.run_report_now_modal .custom-radio input:checked+.radio-btn {
  border: 2px solid #FFDAE9;
}

.run_report_now_modal .custom-radio input:checked+.radio-btn>i {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.job_date_filter.run_report_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100% !important;
}

.run_report_now_modal .radio-buttons {
  display: flex;
}

.internal_link_checker_pie {
  display: flex;
  justify-content: center;
}

.internal_link_checker_pie .row.pie_chart_row {
  width: 45%;
}

.cnt_hdr_blw_wrap.brand_mentions_analyze_button_wrap {
  margin-left: 43px;
  margin-top: 13px;
}

.new_links_table.backlinks_left_remove .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3) {
  display: block;
  padding-left: 35px !important;

}

.run_report_filter .MuiDialogActions-root.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
  padding-bottom: 25px !important;
}

.run_report_filter .run_report_info {
  /* color: #fff; */
  color: #000000;
  padding-left: 20px;
}

.site_audit_settings_priority {
  width: 23%;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.user_experince_form_container .form_row {
  display: flex;
  margin-bottom: 25px;
  gap: 25px;
}

.user_experince_form_container label {
  color: #0b2033;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.user_experince_form_container label span {
  font-weight: 100 !important;
  /* font-size: 15px; */
}


.user_experince_form_container .form_column {
  width: 50%;
  display: flex;
}

.user_experieceover_all_container .cnt_hdr_top_wrap {
  margin-bottom: 10px;
}

.user_experince_form_container_wrap {
  padding: 47px;
  border: 2px solid #ff601f;
}

.user_experince_form_container .form_column .label {
  width: 50%;
}

.user_experince_form_container .form_column .input {
  width: 50%;
}

.user_experince_form_container .form_column .input input[type='text'],
.user_experince_form_container .form_column .input input[type='number'] {
  padding: 0 0 0 17px;
  border-radius: 4px;
  width: 250px;
  height: 37px;
}

@media(max-width:500px) {

  .user_experince_form_container .form_column .input input[type='text'],
  .user_experince_form_container .form_column .input input[type='number'] {
    width: 181px;
  }
}

.user_experince_form_err {
  margin-bottom: 15px;
}

.user_experince_form_button {
  display: flex;
  justify-content: center;
}

.table_td_regression {
  color: #000000 !important;
  font-weight: 600;
}

.regionalsetting {
  display: flex !important;
  gap: 30px !important;
  justify-content: space-between !important;
}

.userdatas_inner.keywordform_outer .websitename label {
  width: 30%;
}

.userdatas_inner.keywordform_outer .user_country input {
  /* width: 56%; */
}

.userdatas_inner.keywordform_outer .user_country input[type="text"] {
  /* width: 54%!important; */
  border: 1px solid #ff601f;
  background-color: #ffffff;
}

.plagiarisam_missing_waring_wrap.detailspell {
  margin-left: 90px;
  margin-top: 30px;
}

.plagiarisam_missing_waring_wrap_databox {
  /* color: #0b2033; */
  color: #fff !important;
  width: 153px;
  height: 100px;
  /* background: #fe874e; */
  background: #ff601f !important;
  border-radius: 15px;
  opacity: 1;
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  margin-right: 21px;
  padding: 10px 21px;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  flex-direction: column-reverse;
  align-items: center;
  /* margin-top: 55px;
  margin-left: 40px;
  margin-bottom: 0px; */
  margin: 0 auto;
}

.plagiarisam_missing_waring_wrap_databox .p {
  margin-bottom: 10px;
}

.branded_term {
  margin-top: 20px;
}

a.seometricdata {
  text-decoration: none !important;
  color: white !important;
  display: flex !important;
  width: 100% !important;
  /* justify-content: flex-start !important; */
  /* justify-content: space-around !important; */
  margin-left: 40px !important;
}

a.seometricdata .value {

  margin-right: 25px;
}

img {
  cursor: auto !important;
}

/* img:hover  { 
    background-color: #000000;
    zoom: 0.9;
} */
.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
  align-items: center;
}

.listdropdown_drop {
  /* position: absolute; */
  /* left: 600px !important; */
  /* right: 312px; */
  bottom: 79px;
  justify-content: space-evenly;
  display: flex;
  /* top: 271px; */
  /* justify-content: space-between;
    margin-left: 30px; */
}

/* .over_all_container.over_all_link{
    overflow: clip !important;
} */
.cnt_hdr_top_lft_wrap {
  line-height: 1.5;
}

.top_action_list .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(3) {
  display: block;
  padding-left: 90px;

}

.listdropdown_drop_site {
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-left: auto;
  margin-right: auto;
  /* position: absolute; */
  left: 800px !important;
  /* right: 312px; */
  bottom: 79px;
  display: flex;
  top: 240px;
  margin-top: 25px;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
  background: transparent;
  cursor: pointer !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
  right: 0px !important;
  display: flex;
  gap: 10px;
  align-items: center !important;
}

.sidebar {
  margin-top: 10px;
  margin-right: 20px;
  height: 95%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  /* display: grid; */
  place-items: center;
  z-index: 9999999999;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 5px;

  /* word-break: break-all; */
}

.sidebar div {
  cursor: pointer;
  left: 1em;
  top: 1em;
  padding-left: 10px;
  padding-right: 5px;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
  /* font-size: 14px; */
  text-align: start;
  font-family: Poppins;
  word-break: break-word;

}

.styles_faq-row-wrapper__3vA1D {
  width: 200px !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
  /* font-size: 14px !important; */
  text-align: start;
  font-family: Poppins;
}

/* .faq_class{
    padding:6px;
} */
/* .faq_move_class{
    margin-right: 158px;
    position: absolute;
    left: 87%;
} */
.domainnamelist .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor:nth-of-type(7) {
  padding-right: 125px;
}

.css-17n9xuu-MuiDataGrid-root .MuiDataGrid-row {
  align-items: center;
  /* margin-left: -40px; */
}

.plagirisam_detail .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell:first-child {
  position: unset;
}

.google_analytics_err .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  white-space: normal;
  line-height: normal;
  overflow: visible;
}

.google_analytics_err .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor {
  height: 151px !important;
}

.MuiDataGrid-iconButtonContainer.css-ltf0zy-MuiDataGrid-iconButtonContainer {
  padding-left: 8px;
}

.plagiarisam_missing_waring_wrap.filename {
  width: 350px;
}

.cnt_hdr_content_box_outer.fileaccesstopinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.cnt_hdr_content_table.fileaccessreport_inner {
  background-color: #ffffff !important;
  border-radius: 10px;
  width: 70%;
  margin: 20px auto;
}


.upload-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff601f;
  color: white;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  margin-right: 0px;
  border: 1px solid #ff601f;
}


.blubutton {
  flex-basis: 30%;
  background: #ff601f;
  ;
  color: #fff;
}

.fileform {
  display: flex;
}

.upload-container {
  display: flex;
  flex-basis: 60%;
}

.accessfile.no_pad .input_col input {
  display: none;
}

.fileform p.file-info {
  width: 67%;
  color: #000000;
  border: 1px solid #fe874e;
  margin: 0px;
  text-align: center;
  padding: 10px 0px 0px;
  overflow: hidden;
}

.field-style {
  color: #000000;
  border: 1px solid #fe874e;
  padding: 10px 12px;
  width: 66%;
  margin: 0px;
  border-radius: 0px 4px 4px 0px;
}

.genrate_input_col {
  margin-top: 40px;
}

.bell-container {
  position: relative;
  /* font-size: 2rem; */
  /* margin-top:22px; */
  /* margin-left: 700px; */
  float: right;
}

.bell {
  position: relative;
  display: inline-block;
}

.bellnotification {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  /* font-size: 0.75rem; */
  font-weight: bold;
  line-height: 1;
  text-align: center;
}

.accessbility_report_score_table .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  background-color: #ffffff;
}

.accessbility_report_score_table .MuiDataGrid-virtualScrollerRenderZone {
  background-color: #ffffff;
}

.MuiDataGrid-virtualScrollerRenderZone {
  background-color: #ffffff;
}



.row-odd-con {
  background-color: #ffffff;
  /* White */
}

span.bell-notification {
  position: absolute;
  top: -3px;
  /* bottom: 0px; */
  /* font-size: 20px; */
  right: 0px;
  /* background-color: #000000; */
  display: table;
}

/* .userdatas_role .userdatas_inner.keywordform_outer .user_country input {
    width: 12% !important;
}

.userdatas_role .userdatas_inner.keywordform_outer .websitename label {
    width: 40% !important;
    height: 40px !important;
}

.userdatas_role .userdatas_inner.keywordform_outer .websitename label.webtype {
    left: 50px !important;
    position: relative !important;
}

.userdatas_role .userdatas_inner.keywordform_outer .websitename select.form-select {
    right: 25px !important;
    position: relative !important;
} */
.user-role-container {
  display: flex;
  justify-content: center;
}

.user-role-inner {
  width: 100%;
}

/* .permissions-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  } */
.permissions-list {
  display: flex;
  flex-direction: column;
}

.permissions-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media(max-width:500px) {
  .permissions-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.permission-item {
  flex: 1;
  margin-right: 20px;
}

.permission-item:last-child {
  margin-right: 0;
}

.user-role-container .btn {
  /* padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer; */
  border-radius: 10px;
  /* font-size: 20px; */
  position: relative;
  color: #fff;
  width: 40%;
  padding: 20px 20px;
  background: #ff601f;
  margin: 0em 1em;
}

.user-role-container .btn:hover {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 7px 5px #ff601fd6;
}

.user-role-container .error-message {
  /* color: rgb(255, 255, 255); */
  color: #ff0000;
  /* font-size: 0.9em; */
}

.user-role-container .form-actions {
  display: flex;
  gap: 10px;
}

.user-role-container .form-container {
  padding: 20px;
}

.user-role-container .form-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-role-container .form-group {
  display: flex;
  flex-direction: column;
}

.user-role-container .form-input,
.form-select {
  padding: 10px;
  /* border: 1px solid #ccc; */
  border: 1px solid #ff601f;
  border-radius: 5px;
  /* width: 378px; */
}

.overall-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  /* background-color: #f9f9f9; */
  /* background: #fe874e 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  border: 2px solid #ff601f;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-form .overall-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  border: 2px solid #ff601f;

  /* background-color: #f9f9f9; */
  /* background: #fe874e 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-user .overall-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  /* background-color: #f9f9f9; */
  /* background: #fe874e 0% 0% no-repeat padding-box; */
  border: 2px solid #ff601f;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

p#alert-dialog-description {
  color: #030303;
}

.customerrormsg {
  color: red;
  margin-top: 4px;

}

.filter-section {
  /* width: 100%;
    /* margin: 0px 27px 25px 13px; */
  /* display: inline-block;
    margin-left: 15px;
    position: relative;
    margin-right: 3px !important; */
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
}

.over_all_inner {
  padding: 20px;
  background-color: #f5f5f5;
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-left: auto;
  margin-right: auto;

}

.card_custom {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-item {
  display: flex;
  flex-direction: column;
}

.listdropdown_titl {
  margin-bottom: 8px;
  font-weight: bold;
}

.customchart-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 72%;
  margin: 40px auto;
}

.custom-error-message {
  color: red;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  font-size: 30px;
}




.my-custom-select__control {
  border-radius: 5px !important;
  border-color: #ccc !important;
  box-shadow: none !important;
}

.my-custom-select__control:hover {
  border-color: #aaa !important;
}

.my-custom-select__menu {
  border-radius: 5px !important;
  margin-top: 0 !important;
}

.my-custom-select__option {
  background-color: #fff !important;
  color: #333 !important;
}

.my-custom-select__option--is-focused {
  background-color: #e6e6e6 !important;
}

.my-custom-select__option--is-selected {
  background-color: #f0f0f0 !important;
}



/* #react-select-3-listbox div{
    position: relative;
    bottom: 10px;
  } */

.my-custom-select__menu-list>div {
  position: relative;
  top: 10px;
  bottom: 10px;
  /* padding-left: 10px; */
  padding: 10px;
}

.my-custom-select__menu-list>div:hover {
  background-color: #e6e7eb;
  cursor: pointer;
}

.my-custom-select__menu-list>div:active {
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
}

/* .over_all_accessibility{
    font-family: Poppins !important;
  }
  .over_all_accessibility .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cell{
    font-family: Poppins !important;
  } */

.msg_errormsg {
  margin-left: 230px;
}

.plagarism_analyzer_row_standard {
  display: flex;
  padding: unset;
  margin-top: 30px;
  margin-left: 150px;
  align-items: center;
  gap: 30px;
}

label.reportName {
  /* font-size: 25px; */
  color: #0b2033;
  font-weight: 700;
  /* font-size: 22px; */
  opacity: 1;
}

.svg_searchdata {
  margin-left: 30px;
}

/* .standardbutton{
    margin-left: 40px !important;
} */
.campapgin_settings_list_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  display: flex;
}

.dummyselect {
  margin-left: 30px;
}

.multiselect-container .multiselect-input {
  text-align: left !important;
}

.campapgin_settings_list_table .css-1hsc7hf-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell:nth-of-type(2) {
  padding-left: 13px !important;
}

/* .campapgin_settings_list_table .MuiDataGrid-columnHeader .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeader--sortable
 .MuiDataGrid-withBorderColor:nth-child(2){
    padding-left: 53px !important;
 } */
input#search_input::placeholder {
  position: relative;
  left: -30px;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementBottom.css-1k51tf5-MuiTooltip-tooltip {
  margin-left: 85px;
}

.css-4dmook-MuiDataGrid-root .MuiDataGrid-cell:first-child {
  position: relative !important;
}

/* .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center{
      justify-content: flex-start !important;
    } */

@media(min-width:1200px) {

  /* .brand_mention{
    margin-left: 200px;
  } */
  .css-iwe6dv-MuiResponsiveChart-container>svg {
    width: 100%;
    height: 100%;
    align-items: end;
  }

  .css-iwe6dv-MuiResponsiveChart-container>svg {
    width: 100%;
    height: 100%;
  }
}

.css-iwe6dv-MuiResponsiveChart-container>svg {
  width: 1009px;
  height: 100%;
}

.main-header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1037;
}

@media (max-width: 700) {
  span.bell-notification {
    top: 4px;
    margin-right: 10px;
  }
}

@media (min-width: 500) and (max-width: 800) {
  span.bell-notification {
    top: 0px;
    margin-right: 15px;
  }
}

/* Tablet View */
@media (max-width: 780px) {
  span.nav_key_txt.side_nav_txt.nav_txt_otr_wrap {
    display: block !important;
  }

  .site_audit_dashboard_outer .card_row {
    display: flex;
  }

  .external_link_data_row {
    padding: 0px !important;
  }

  .side_menu_inner_wrap {
    max-height: 1015px;
  }

  /* .side_menu_outer_wrap{
    background: none !important;
  } */

  .ContentAuditWrap .cnt_hdr_top_wrap {
    padding: 45px 0px 45px 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fe874e;
  }

  .form-select {
    width: 200px !important;
  }

  .keywordform_outer .form-select {
    width: 100%
  }

  .acc-rpt-list img {
    margin-left: 30px !important;

  }

  .bell-container {
    position: relative;
    /* font-size: 2rem; */
    margin-top: 10px;
    /* margin-left: 300px; */
    float: right;
  }

  .schemaOutputeso li .val {
    width: 70% !important;
  }

  .schemaOutputeso {
    /* font-size: 12px; */
  }

  .user_experince_form_container .form_column {
    display: block;
    width: 100%;
  }

  .plagarism_analyzer_row_standard {
    margin-left: 0px !important;
  }

  .schemaOutputeso li .key {
    width: 30% !important;
  }

  .css-iwe6dv-MuiResponsiveChart-container>svg {
    width: 70% !important;
    height: 100% !important;
  }

  .css-1u0lry5-MuiChartsLegend-root text {
    /* font-size: 11.5px !important; */
  }

  /* .css-iwe6dv-MuiResponsiveChart-container>svg {
    width: 900px;
    height: 100%;
} */
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 10px !important;
    border: 0;
  }

  .daterangepicker_wrap {
    display: block;
    margin-top: 20px;
    gap: 10px;
    margin-left: 48px;
  }

  .brand_container g.MuiChartsLegend-root.MuiChartsLegend-column.css-1u0lry5-MuiChartsLegend-root {
    transform: translate(-75px, 180px);
  }

  /* .side_menu_outer_wrap{
        max-height: 180vh;
        height: 150vh;
    } */
  .user_experince_form_container .form_column .label {
    width: 50%;
    display: block;
  }

  a.seometricdata {
    /* font-size: 12px; */
  }

  .add-form .overall-container {
    padding: 20px;
    max-width: 500px;
  }

  .user_experince_form_container .form_column .input {
    width: 50%;
    margin-top: 10px;
  }

  .edit-user .overall-container {
    padding: 20px;
    max-width: 500px;
  }

  .cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e9e9e92e;
  }

  /* .dashboard_traffic_widget{
        width: 500px;
      } */
  .faq_class {
    padding: 2px;
  }

  .site_audit_dashboard_outer .card_row .cols .versus {
    text-align: center;
    /* font-size: 12px; */
  }

  .site_audit_dashboard_outer .card_row .cols .heading {
    text-align: center;
    color: #fe874e;
    /* font-size: 16px; */
    font-weight: 700;
  }

  select#drp_inner_top_wrap,
  select#drp_inner_bottom_wrap {
    /* font-size: 12px; */
    color: #000000;
    text-transform: capitalize;
    background: #ffffff;
    border: 0px;
    border-radius: 5px;
    padding: 10px 55px 10px 4px;
    border: 1px solid #ff601f;
  }

  .top_icons_grp span.account_set_wrap svg {
    fill: #000000;
    height: 60px;
    width: 40px;
  }

  .header_wrap {
    max-height: 70px;
    padding-right: 10px;
  }

  .rounded-dot-row .kw-trend-opt {
    /* font-size: 10px; */
  }

  .below_note_ranking {
    padding: 5px;
    /* font-size: 12px; */
  }

  .Logo_wrap img.Siteicon {
    width: 30px;
    background: #ffffff;
    border-radius: 10px;
  }

  .Logo_wrap img.Siteimage {
    width: 120px;
    background: #ffffff;
    border-radius: 10px;
  }

  .MuiDataGrid-main .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
    /* background-color: #000000c9 !important; */
    width: 20px;
    height: 20px;
  }

  /* .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2{
        font-size: 30px;
        font-weight: 20px;
    } */

}

.css-17n9xuu-MuiDataGrid-root .MuiDataGrid-cell--withRenderer {
  width: 100% !important;

}

/* .css-t89xny-MuiDataGrid-columnHeaderTitle{
  margin-left: 20px;
} */

@media (max-width: 500px) and (min-width: 381px) {

  /* .reset_page_container.tab_otr_outer .frm_otr_wrap .form-group.email_grp{
  } */
  .reset_page_container.tab_otr_outer button.sbt_btn {
    padding: 25px 0px;
    margin-bottom: 35px;
  }

  .reset_page_container.tab_otr_outer a.lgn_rdrct_link {
    display: flex;
  }

  /* .rest_scs_outer_container.confirm_sec_outer_container .rest_inst_wrap {
    width: 230px !important;
} */
  .rest_scs_outer_container.confirm_sec_outer_container .cnfrm p {
    /* width: 200px !important; */
    /* font-size: 14px; */
    text-align: center;
    color: #fff;
  }

  .rest_scs_outer_container .rest_scs_ins_container {
    border-radius: 15px;
    /* padding: 25px 100px 10px !important; */
    margin: 40px 70px;
    background: #fe874e 0% 0% no-repeat padding-box;
  }

  .acc-rpt-list img {
    margin-left: 10px !important;
    width: 330px !important;

  }

  .int-link-check .App {
    margin: 34px;
  }

  .error_gwt ul.infoGraphic li {
    padding: 0px;
  }

  /* .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    width: 200px;   
} */
  .bell-container {
    position: relative;
    /* font-size: 2rem; */
    margin-top: 10px;
    margin-left: 0px;
    float: right;
  }

  /* .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
    margin-top: 100px !important;
  } */
  /* .cnt_audit_outer_wrap .site-adt-rpt img  {
    translate: -50px !important;
    margin-top: 100px !important;
    
  } */

  /* .rest_scs_outer_container.confirm_sec_outer_container .rest_inst_wrap {
    width: 200px;
    translate: -85px !important;
} */
  /* .change_password_eye .toggle-password {
    right: 20px !important;
  } */
}

@media (max-width: 780px) and (min-width: 501px) {
  .plagiarisamdetail_otr_wrap .cnt_hdr_content_box_outer {
    display: block;
  }

  .plagiarisam_missing_waring_wrap {
    height: 100px;
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {

  /* .run_report_now_modal .radio-btn{
    width: 170px !important;
  } */
  .run_report_now_modal .radio-btn {
    width: 130px !important;
    height: 112px;
  }

  /* .schema_anaysis_outer_wrap .App {
margin-top: 60px !important;
} */
  .tab_otr_outer .tab_link_outr_wrap a {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 11px;
    text-decoration: none;
    color: #0b2033;
    padding: 10px !important;
    /* font-size: 10px; */
    align-items: center;
  }

  .MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding: 0px !important;
  }

  .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    padding: 0px !important;
  }

  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
    /* font-size: 10px !important; */
    margin-left: 0px !important;
  }

  .css-levciy-MuiTablePagination-displayedRows {
    /* font-size: 10px !important; */

  }

  .css-pdct74-MuiTablePagination-selectLabel {
    /* font-size: 10px !important; */
  }

  .site_audit_dashboard_outer .card_row {
    display: inline;
  }

  .header_inner_top_wrap,
  .header_inner_btm_wrap {
    gap: 10px !important;
  }

  .bell-container {
    padding-right: 10px;
    /* font-size: 1rem !important; */
    bottom: 10px;
  }

  .MuiTablePagination-root.css-rtrcn9-MuiTablePagination-root {
    overflow: hidden !important;
  }

  .css-16c50h-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select {
    padding-right: 0px !important;
  }

  /* select#drp_inner_top_wrap, select#drp_inner_bottom_wrap {
    padding: 5px 15px 5px 4px;
} */
  .bell-container {
    position: relative;
    margin-top: 10px;
    margin-left: 0px !important;
    float: right;
  }

  .top_icons_grp span.account_set_wrap svg {
    fill: #000000;
    height: 60px;
    width: 25px;
  }

  .bell-container {
    bottom: 10px;
  }

  #SvgjsSvg1210 {
    width: 465;
    height: 108.5;
    transform: translate(0, 0);
  }

  .apexcharts-canvas {
    width: 100% !important;
    height: auto !important;
  }

  .apexcharts-legend {
    display: block;
    position: relative;
    top: 0;
    margin-top: 10px;
    text-align: center;
  }

  /* .brand_mention{
    margin-left: 100px;
  } */
  .brand_mention .row.pie_chart_row {
    /* width: 105% !important; */
    height: 200px;
  }

  .css-rtrcn9-MuiTablePagination-root:last-child {
    padding: 0;
    padding-top: 60px;
  }

  .css-17n9xuu-MuiDataGrid-root,
  .css-17n9xuu-MuiDataGrid-root [class^=MuiDataGrid] {
    border: none;
  }

  .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-input {
    display: block !important;
  }

  /* .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
    display: block !important;
} */
  .itemdata::marker {
    content: 'Your content here';
    /* Add your content */
    display: inline-block;
    /* Make it behave like a span */
    margin-right: 8px;
    /* Space between the content and the list item */
  }

  /* For screens 500px or smaller */

  .itemdata::marker {
    content: '';
    /* Remove the before content */
  }

  .itemdata span.marker-content {
    display: inline-block;
    /* Show span like ::before */
    margin-right: 8px;
  }


  .css-jpln7h-MuiTabs-scroller {
    white-space: normal !important;
    translate: 45px;
  }

  .pagehealth_missing_waring_wrap.plagiarisam_chart_outer {
    /* font-size: 12px !important; */

  }

  .pagehealth_missing_waring_wrap.plagiarisam_chart_outer {
    width: 120px !important;
    margin-bottom: 20px;
  }

  .page_health .cnt_hdr_content_box_outer {
    translate: 55px;
    display: block;
  }

  .schema_anaysis_outer_wrap p {
    max-width: 480px !important;
    word-break: break-all;
  }

  .paypal-button-container {
    min-width: 100px;
    max-width: 180px;
  }

  .subscription_wrap .plans_list .choose_button_wrap button {
    padding: 2px;
  }

  .subscription_wrap .plans_list .choose_button_wrap button {
    width: 70%;
    margin-left: 10px;
    /* font-size: 10px */
  }

  .subscription_wrap .container .period {
    /* font-size: 10px; */
    font-weight: 500;
  }

  .subscription_wrap .plans_list .container>div p {
    /* width: 150px; */
    /* font-size: 9px; */
  }

  .subscription_wrap .container .price,
  .type {
    color: #635858;
    /* font-size: 10px; */
  }

  .subscription_wrap .plans_list .container>div {
    margin-left: 0px;
  }

  .subscription_wrap .plan_description p {
    /* font-size: 20px; */
    color: #0b2033;
  }

  .sup-plan {
    margin-left: 30px;
  }

  .subscription_wrap .free_plan_wrap .free_plan_info .info {
    /* font-size: 16px; */
  }

  .rest_scs_outer_container.confirm_sec_outer_container .cnfrm {
    min-width: 100% !important;
    /* translate: -45px !important;  */
  }

  .rest_scs_outer_container.confirm_sec_outer_container button.sbt_btn {
    width: 80% !important;
    padding: 10px 10px !important;

  }

  .rest_scs_outer_container.confirm_sec_outer_container input#otppas {
    /* font-size: 9px !important; */
  }

  /* .change_password_eye .toggle-password {
    width: 20px ;
} */
  /* .rest_scs_outer_container.confirm_sec_outer_container .rest_inst_wrap {
    width: 200px;
    translate: -65px;
  } */
  .rest_scs_outer_container h1.scs_tt {
    /* font-size: 22px !important; */
  }

  /* .confirm_sec_outer_container .rest_scs_ins_container img.Eso_rst_img {
    transform: translateX(-105px) !important;
} */
  /* .schemaOutputeso li .key {
    width: 100% !important;
} */
  .val {
    margin-bottom: 0px !important;
  }

  /* .schemaOutputeso li .val {
    width: 1200px !important;
} */
  .schemaOutputeso {
    /* font-size: 8px; */
  }

  .user_experince_form_container .form_row {
    display: block;
  }

  .site_Chart_info_inner canvas {
    width: 160px !important;
  }

  .filter_icon_dashboard.top_5 {
    position: relative;
  }

  .plagiarisam_missing_waring_wrap {
    margin-bottom: 30px;
  }

  .plagiarisamdetail_otr_wrap .cnt_hdr_content_box_outer {
    display: block;
    margin-left: 30px;
  }

  /* .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap {
    margin-bottom: 100px;
} */
  .cnt_hdr_top_lft_wrap {
    line-height: 1.5;
    width: 70% !important;
  }

  .below_note_ranking.icon {
    display: block;
  }

  .css-1t51roe-DropDown {
    width: 180px;
  }

  .search_key_wrap.searchvolume_key_wrap .plagarism_analyzer_row .input_col {
    width: 100%;
  }

  .col label.form-label {
    display: block;
  }

  .linechartdesign {
    max-width: 200px;

  }

  .rounded-dot-row {
    display: block;
    padding-left: 50px;
    width: auto;
  }

  .keywordform_outer.userdatas_inner.keywordform_outer .websitename label {
    margin-left: 40px;
    width: 100%;
  }

  .keywordform_outer .user_country select {
    /* font-size: 12px; */
  }

  .keywordform_outer.userdatas_inner.keywordform_outer .user_country input {
    width: 45%;
  }

  .keywordform_outer .form-group.user_country.websitename {
    width: 100%;
  }

  .colhead.inputhead {
    margin-top: 20px;
  }

  .colhead.brandhead {
    display: block;
  }

  .brand_term_add_form .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 100% !important;
  }

  .row.addbranded_terms {
    display: block;
  }

  .standardbutton {
    margin-left: 110px !important;
  }

  .plagarism_analyzer_row_standard {
    display: block;
  }

  .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    width: 140px;
  }

  .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    border: 1px solid #0b2033;
    border-radius: 4px;
    width: 145px !important;
  }

  .plagarism_analyzer_row_standard .button_col {
    width: 65%;
    margin-bottom: 1rem;
  }

  .css-fdllt0-DropDown {
    width: 200px !important;
  }

  .show_in_progress_brand_mention .topHalf {
    width: 80%;
    color: white;
    overflow: hidden;
    min-height: 20px;
    position: relative;
    padding: 40px 10px;
    background: transparent;
  }

  .google_traffic_error {
    width: 30px;
  }

  body {
    max-height: 1000px;
  }

  .over_all_container {
    max-height: 1000px;
    /* padding-left: 40px; */
  }

  .drp_inner_tp_wrap:after {
    translate: -45px;
  }

  select#drp_inner_top_wrap,
  select#drp_inner_bottom_wrap {
    width: 120px;
  }

  .schemaOutputeso .key_val_row {
    display: flex;
    /* height: 40px !important; */
    /* align-items: center; */
    /* font-size: 11px; */
    min-height: 40px !important;
    margin-bottom: 15px;
    gap: 20px;
  }

  .css-1u0lry5-MuiChartsLegend-root text {
    /* font-size: 15px !important; */
  }

  .css-1qkz8p7 {
    transform: translateX(30px);
    /* Adjust the value to move it further right */
  }

  .brand_container g.MuiChartsLegend-root.MuiChartsLegend-column.css-1u0lry5-MuiChartsLegend-root {
    transform: translate(-99px, 190px) !important;
    /* transform: translate(-169px, 190px) !important; */
  }

  .css-iwe6dv-MuiResponsiveChart-container path {
    transform: translateX(30px) !important;
  }

  /* .css-iwe6dv-MuiResponsiveChart-container svg {
    transform: translateX(10px);
} */
  .sitemap_generator .settings_form .form_field_row .label_col {
    /* font-size: 14px; */
    width: 40%;
  }

  .sitemap_generator .settings_form .form_field_row .field_col {
    margin-left: 40px;
  }

  .sitemap_generator .settings_form .form_field_row .field_col .two_inputs {
    display: block;
  }

  .sitemap_generator .settings_form .form_field_row .field_col .two_inputs input {
    margin-bottom: 20px;
  }

  .sitemap_generator .type_of_settings {
    display: block;
  }

  .sitemap_generator .type_of_settings .typecol {
    margin-bottom: 20px;
    padding: 10px;
    width: 60%;
  }

  .form-container [type="text"] {
    /* font-size: 15px; */
    width: 250px;
    height: 40px;
  }

  .side_menu_inner_wrap .nav_das_icon.side_nav_icons:before {
    width: 30px;
    height: 20px;
  }

  .side_nav_txt {
    /* font-size: 10px; */
    padding-left: 4px;
  }

  .user-role-inner h2 {
    /* font-size:20px ; */
  }

  .user-role-inner label {
    /* font-size:12px ; */
  }

  .user-role-container .btn {
    border-radius: 10px;
    /* font-size: 16px; */
    position: relative;
    color: #fff;
    width: 41%;
    padding: 10px 20px;
    background: #ff601f;
    margin: 0em 1em;
  }

  .add-form .overall-container {
    padding: 20px;
    max-width: 300px;
    margin: 0 auto;
    margin-right: 10px;
    /* background-color: #f9f9f9; */
    /* background: #fe874e 0% 0% no-repeat padding-box; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .edit-user .overall-container {
    padding: 20px;
    max-width: 250px;

  }

  .daterangepicker_wrap {
    flex-direction: column;
    /* Stacks items vertically */
    margin-left: 0;
    /* Optional: adjust margin if needed */
  }

  .daterangepicker_wrap>* {
    flex: 1 1 100%;
    /* Each item takes full width */
  }

  .daterangepicker_wrap .daterangepicker_wrap_submit {
    width: 40%;
  }

  /* .site_Chart_info_inner {
      overflow-x: auto; 
      white-space: nowrap; 
      display: flex; 
      max-height: 5000px;
    } */
  .rounded-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
  }

  .linechartdesign {
    min-width: 200px;
    height: 200px;
  }

  /*   
    .rounded-dot-row {
      flex-wrap: nowrap;
    } */
}

/* Add this CSS to your stylesheet or component */

.external_link_data_row {
  display: flex;
  /* Use flexbox for flexible layout */
  flex-wrap: wrap;
  /* Allow items to wrap to the next line */
  gap: 16px;
  /* Add space between the columns */
}

.ranking_summary_col {
  flex: 1 2 calc(15% - 16px);
  /* Flex-grow, flex-shrink, flex-basis */
  box-sizing: border-box;
  /* Include padding and border in width */
  padding: 10px;
  /* Add padding */
  border: 1px solid #ddd;
  /* Optional border for better visibility */
  background-color: #f9f9f9;
  /* Optional background color */
}

.content_col {
  text-align: center;
  /* Center-align the text */
}

@media (max-width: 420px) {

  .header_inner_top_wrap,
  .header_inner_btm_wrap {
    gap: 30px;
    padding-left: 50px;
  }
}

@media (max-width: 700px) and (min-width: 421px) {

  .header_inner_top_wrap,
  .header_inner_btm_wrap {
    padding-left: 0px;
  }
}

@media (max-width: 800px) and (min-width: 701px) {

  .header_inner_top_wrap,
  .header_inner_btm_wrap {
    padding-left: 70px;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .rest_scs_outer_container.confirm_sec_outer_container input#otppas {
    /* font-size: 12px !important; */
  }

  .rest_scs_outer_container.confirm_sec_outer_container button.sbt_btn {
    /* font-size: 12px !important; */
  }

  .internal_link_checker_pie .row.pie_chart_row {
    width: 100%;
  }

  .menu_toggle_btn {
    display: block;
    position: fixed;
  }

  .side_menu_outer_wrap {
    background: transparent;
    position: absolute !important;
  }

  .side_menu_inner_wrap {
    background: #ffffff;
    top: 15px;
  }

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex;
  }

  /* .header_inner_btm_wrap{
      padding-right: 40px;
    } */
  /* .sidebar{
      width:36vw;
    } */


  .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
    padding-right: 3em;
    /* width: 300px; */
  }

  .ranking_summary_col {
    flex: 1 2 calc(45% - 16px);
    /* Each column takes up 50% width */
  }

  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn {
    display: flex;
    align-items: center;
    gap: 25px;
    padding-left: 15px;
    overflow: hidden;
    position: relative;
  }

  .error_plagarism_report .form-label {
    /* font-size: 10px; */
  }
}

@media (max-width: 480px) {
  .cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    padding: 15px 10px 15px 0px;
  }

  .styles_faq-row-wrapper__3vA1D h2 {
    /* font-size:20px !important; */
  }

  .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
    /* font-size: 12px; */
    text-align: start;
    font-family: Poppins;
    width: 150px;
  }

  .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    /* font-size: 12px !important; */
    text-align: start;
    font-family: Poppins;
  }

  .websitename label {
    /* width: 19%; */
    /* font-size: 12px; */
    margin-right: 10px;
  }

  .userdatas_inner p {
    /* font-size: 14px; */
    color: #fff;
  }

  .user_country input {
    /* font-size: 12px; */
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #111112;
    width: 40%;
    padding: 10px !important;
    color: #1a1818;
  }

  /* .dashboard_traffic_widget {
        width: 250px;
        overflow-y: hidden;
    } */
  .dashboard_traffic_widget .h3 {
    /* font-size: 20px; */
  }

  .ranking_summary_col {
    flex: 1 1 100%;
    /* Each column takes full width on very small screens */
    flex: 1 1 calc(45% - 16px);
    /* Each column takes up 50% width */
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    /* width: 60% !important; */
    height: unset !important;
  }
}

.link_cnt_hdr_blw_wrap {
  flex-wrap: wrap;
  /* Allow items to wrap onto multiple lines */
  gap: 10px;
  /* Add space between buttons */
  justify-content: space-between;
  /* Distribute space between buttons */
}

/* .link_rn_rpt_btn {
    flex: 1 1 calc(50% - 10px); 
    padding: 10px; 
    text-align: center;
    cursor: pointer;
    box-sizing: border-box; 
  } */

/* Responsive layout for tablet view (max-width 992px) */
@media (max-width: 992px) {
  .link_rn_rpt_btn {
    flex: 1 1 calc(20% - 10px);
    /* Each button takes up 50% of the row */
  }
}

/* Responsive layout for mobile view (max-width 768px) */
@media (max-width: 508px) {
  .link_rn_rpt_btn {
    flex: 1 1 calc(20% - 10px);
    /* Each button takes up 50% of the row */
  }

  .link_cnt_hdr_blw_wrap {
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
}


/* Base styles for desktop and larger screens */
/* .rounded-dot-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  } */

/* .rounded-dot-col {
    display: flex;
    align-items: center;
    gap: 5px; 
    box-sizing: border-box; 
  } */

/* Optional: Adjust styles for individual rounded dots */
/* .rounded-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  } */



/* Tablet view (between 768px and 992px) */
/* @media (max-width: 992px) {
    .rounded-dot-col {
      flex: 1 1 calc(50% - 10px);
    }
  } */

/* Mobile view (max-width: 768px) */
@media (max-width: 508px) {
  .show_in_progress_brand_mention .topHalf h1 {
    /* font-size: 1.25rem; */
  }

  .rounded-dot-col {
    flex: 1 1 calc(25% - 10px);
    /* Full width for each item, single column */
    margin-right: 1px;
    justify-content: center;
    width: max-content;
  }
}

/*   
    .internal_outsource_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor{
        margin-left: 280px;
    } */

.internal_link_checker_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(2) {
  display: flex;
}

.topaciton_data .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor:nth-of-type(3) {
  display: flex;
}

/* css style for nav bar */
.apexcharts-legend-text {
  position: absolute;
}

/* Hide menu toggle button on larger screens */
.menu_toggle_btn {
  display: none;
  background: none;
  border: #ff601f;
  /* font-size: 24px; */
  margin: 20px;
  cursor: pointer;
}

/* .internal_link_checker_pie{
    display: block;
  } */
.apexcharts-svg {
  min-height: unset !important;
  width: 100%;
  /* height:100%; */

}

.site_regress_chart_info {
  min-height: unset !important;
}

/* Side menu default style */
.side_menu_inner_wrap {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

/* Expanded class for side menu when toggle is clicked */
.side_menu_inner_wrap.expanded {
  display: block;
}


/* Media query for smaller screens */
@media (max-width: 768px) {

  /* .organic_search_data.site_regress_chart_info{
      height: 400px !important;
    } */
  .site_regress_chart_info.reg-test {
    height: 450px !important;
  }

  /* .site_regress_chart_info {
      height: 200px !important;
    } */

  .apexcharts-svg {
    min-height: unset !important;
    width: 100%;

  }

  .side_menu_inner_wrap span.nav_hover_cnt_wrap {
    /* font-size: 10px; */
  }

  .Logo_wrap img.Siteicon {
    width: 20px;
  }

  .Logo_wrap img.Siteimage {
    width: 90px;
  }

  .side_menu_inner_wrap {
    display: none;
    width: 100%;
    /* Full width for mobile view */
  }

  .menu_toggle_btn {
    display: block;
    /* Show toggle button */
  }

  .side_menu_inner_wrap.expanded {
    display: flex;
    /* Show menu when expanded */
  }

  .drop_menu {
    position: absolute;
    top: 51px;
    background: #ffffff;
    padding: 10px;
    right: 1%;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #fe874e;
  }

  .drop_menu .menu {
    fill: #0b2033;
    color: #0b2033;
    cursor: pointer;
    text-decoration: none;
    /* font-size: 10px; */
    padding-bottom: 5px;
  }
}

@media (max-width: 500px) {
  .google_area_chart_wrap .row.pie_chart_row {
    width: 150%;
    max-width: 200%;
    /* height: 130px; */
  }

  #menu.expanded .menu_toggle_btn {
    left: calc(100% - 20px) !important;
    /* When expanded, move the button to 20px from the edge */
  }

  #menu.expanded .menu_toggle_btn {
    left: calc(100% - 18px) !important;
    /* Adjust the 40px depending on button width */
  }

  .menu_toggle_btn {
    /* background: #ff601f; */
    border: #ff601f;
    /* font-size: 20px; */
    margin: 20px 10px;
    cursor: pointer;
  }

  select#drp_inner_top_wrap,
  select#drp_inner_bottom_wrap {
    margin-left: 10px !important;
  }

  #root>div.over_all_container>div>div>div.dashboard_traffic_widget>div.traffic_report_seven_wrap>div:nth-child(3)>div>div>div>div svg {
    width: 100% !important;
    position: relative;
  }

  #root>div.over_all_container>div>div>div.dashboard_traffic_widget>div.traffic_report_seven_wrap>div:nth-child(3)>div>div>div>div svg foreignobject {
    left: 1em;
  }

  .site_cnt_hdr_blw_wrap {
    flex-direction: column;
    /* Stack buttons vertically */
  }

  .site_rn_rpt_btn {
    flex: 1 1 100%;
    /* Make each button take full width */
    width: calc(100% - 50px);
    /* Adjust width to account for gap */
    margin-bottom: 15px;
  }

  .regionalsetting.run_rpt_btn {
    flex: 1 1 calc(50% - 10px);
    padding: 10px;
    cursor: pointer;
    text-align: center;
    gap: 5px;
    display: flex;
    /* Adjust gap if needed */

  }

  .cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    /* padding: 15px 0px 15px 40px; */
    display: flex;
    gap: 30px;
  }

}

.apexcharts-legend .apx-legend-position-right {
  flex-direction: row;
}


@media (max-width: 500px) {
  .cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    height: auto !important;
  }

  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
    translate: 55px;
  }

  .src-detail .cnt_hdr_top_wrap {
    height: 160px !important;
  }

  .src-detail .App {
    margin-bottom: 100px;
  }

  /* .src-detail .cnt_hdr_top_rgt_wrap img {
        margin-top: 100px;
      } */
  .src-detail .cnt_hdr_content_box_outer {
    display: block !important;
  }

  /* .src-detail .cnt_hdr_top_rgt_wrap
     {
      margin-bottom: 20px !important;
    } */
  .schema_missing_waring_wrap {
    margin-bottom: 10px;
  }

  .cnt_audit_outer_wrap .int-src-link img {
    margin-top: 100px !important;
  }

  /* .cnt_audit_outer_wrap .int-link-check img{
      margin-top: 140px !important;
    }
    */
  /* .int-link-check img{
      margin-top: 140px !important;
    } */
  .external_link_data_row.back_link_trend_data_row {
    width: 800px;
    display: block !important;
    margin-bottom: 30px !important;
    padding: 15px !important;

  }

  .site_Chart_info_inner {
    margin-bottom: 30px !important;
  }

  td img {
    width: 30px !important;
  }

  .ContentAuditWrap table {
    /* font-size: 8px; */
  }

  .cnt_hdr_content_table table.table tr td:first-child {
    min-width: 15px !important;
  }

  .ContentAuditWrap .cnt_hdr_top_wrap {
    height: 120px !important;
  }

  .error_back_schema {
    margin-left: 0px !important;
  }

  .css-iwe6dv-MuiResponsiveChart-container {
    width: 200% !important;
  }

  .brand_container .css-iwe6dv-MuiResponsiveChart-container {
    width: 130% !important;
  }

  .drop-organicsearch .css-1nmdiq5-menu {
    position: relative;
    width: 140px !important;
  }

  .header_wrap {
    padding-left: 60px;
  }

  .side_menu_outer_wrap {
    /* position: -webkit-absolute; Safari */
    position: absolute !important;

  }

  /* .google_area_chart_wrap .row.pie_chart_row{
      width: 65%;
      height: 100px;
    } */
  .cnt_hdr_blw_wrap {
    flex-direction: column;
    /* Stacks items vertically */
  }

  .run_rpt_btn {
    flex: 1 1 100%;
    /* Takes full width in smaller screens */
  }

  .cnt_hdr_blw_wrap {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping if necessary */
    gap: 10px;
    /* Space between items */

  }

  .cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
    padding: 15px 0px 15px 40px;
    display: flex;
    gap: 30px;
    align-items: flex-start;
  }

  .run_rpt_btn {
    flex: 1 1 calc(20% - 10px);
    /* 50% width minus the gap */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
  }

  .listdropdown {
    flex: 1 1 100%;
    /* Takes full width in smaller screens */
    padding-right: 10px;
  }

  .site_audit_dashboard_outer .card_row .cols {
    width: 80%;
    background: #000000;
    border-radius: 6px;
    padding: 10px 10px 30px;
  }
}

.cnt_audit_outer_wrap {
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-left: auto;
  margin-right: auto;
}

.tabs_graph_outer_wrap {
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-left: auto;
  margin-right: auto;
}

.tabs_outer_wrap {
  width: calc(100% - 40px);
  margin-right: 40px;
  margin-left: auto;
  margin-right: auto;
}



@media (max-width: 768px) {

  select#drp_inner_top_wrap,
  select#drp_inner_bottom_wrap {
    margin-left: 50px;
  }

  .site_cnt_hdr_blw_wrap {
    display: flex;
  }

  .site_cnt_hdr_blw_wrap .site_rn_rpt_btn {
    flex: 1 1 calc(50% - 10px);
    /* Allows two buttons per row with some gap */
    padding: 10px;
    text-align: center;
    /* font-size: 14px; */
  }

  .css-17n9xuu-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
    min-width: 1000px;
  }
}

.filter_icon_dashboard .svg {
  height: 20px;
  width: 20px;
}

.link_cnt_hdr_blw_wrap {
  flex-wrap: wrap;
  gap: 10px;
  /* Adjust spacing between buttons as needed */
}

/* .link_rn_rpt_btn {
    flex: 1 1 calc(33.333% - 10px); 
    box-sizing: border-box;
  } */
.apexcharts-xaxis {
  transform: translateX(-150px) !important;
}

@media (max-width: 780px) {
  .transform {
    transform: translateX(200px);
    position: fixed;
    margin: 0;
    transition: all 0.3s ease;
  }

  .transform .menu_toggle_btn {
    margin: 0;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .blur {
    filter: blur(2px);
    transition: filter 0.3s ease;
    overflow: hidden;
    pointer-events: none;
    overflow: hidden !important;
    position: fixed !important;
    width: 100%;
  }

  .side_menu_outer_wrap.expanded .menu_toggle_btn {
    color: white;
    background: #ff601f;
  }

  .side_menu_outer_wrap.expanded {
    background: #ff601f;
    width: 250px !important;
  }

  .side_menu_outer_wrap {
    position: relative;
  }

  .side_menu_inner_wrap.expanded .menu_toggle_btn {
    right: auto;
    left: 10px;
    position: absolute;
  }

  .search_key_wrap .plagarism_analyzer_row .input_col input {
    /* font-size: 14px; */
    width: 93%;
    text-align: center;
    color: #676767;
  }

  .over_all_container {
    height: 1000px;
  }

  .bell-container {
    padding-right: 10px;
    /* font-size: 1.5rem; */
    bottom: 10px;
    margin-top: 27px !important;
  }

  /* .tab-header{
        width: 250px;
    } */
  .tab-header .signbutton {
    /* font-size: 5px; */
  }

  .link_cnt_hdr_blw_wrap {
    /* display: flex; */
    flex-wrap: wrap;
    gap: 10px;
  }

  .link_rn_rpt_btn {
    flex: 1 1 calc(50% - 10px);
    flex: 1 1 calc(33.333% - 10px);
  }
}

@media (max-width: 500px) {

  .apexcharts-xaxis {
    transform: translateX(-100px) !important;
  }

  .side_menu_outer_wrap.expanded {
    background: #ff601f;
    width: 220px !important;
  }

  .cols.ranking_summary_col {
    width: 100%;
  }

  .search_key_wrap .plagarism_analyzer_row .input_col input {
    /* font-size: 12px; */
  }

  .link_cnt_hdr_blw_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Adjust spacing between buttons as needed */
  }

  .link_rn_rpt_btn {
    flex: 1 1 calc(50% - 10px);
    /* 1 button per row */
  }
}

.divider-before::before {
  content: "";
  display: inline-block;
  /* Keeps the divider inline with the text */
  width: 1px;
  /* Width of the divider */
  height: 4em;
  /* Height of the divider, matches the height of the text */
  background-color: #000;
  /* Color of the divider */
  margin-right: 20px;
  /* Space between the divider and the icon */
  vertical-align: middle;
  /* Align the divider vertically with the text */
}

.site_table_blw_wrap {
  display: flex;
  /* Enable Flexbox layout */
  flex-wrap: wrap;
  /* Allow buttons to wrap into new rows when necessary */
  gap: 10px;
  /* Adds a 10px gap between each button */
  width: 100%;
  /* Ensure the container takes the full width */
  justify-content: flex-start;
  /* Align items to the start */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
}

.button_row {
  display: flex;
  /* Enable Flexbox layout */
  flex-wrap: wrap;
  /* Allows items to wrap into new rows */
  gap: 10px;
  /* Adds a 10px gap between buttons */
  width: 100%;
  /* Ensure the row takes the full width */
}

.button_container {
  display: flex;
  /* Enable Flexbox for each button container */
  /* flex-wrap: wrap; Allows buttons to wrap if needed */
  /* width: 100%; Each container will occupy the full width */
  gap: 10px;
  /* Add a gap between buttons */
}

.site_table_blw_wrap button {
  flex: 1 1 calc(12.5% - 10px);
  /* Each button will take up 12.5% of the width, considering gaps */
  padding: 10px;
  /* Adjust padding as needed */
  background-color: #f0f0f0;
  /* Button background color */
  border: none;
  /* Removes default border */
  cursor: pointer;
  /* Changes the cursor to a pointer on hover */
  text-align: center;
  /* Centers the button text */
  box-sizing: border-box;
  /* Include padding and border in the button's total width */
}

@media (max-width: 768px) {
  .button_row {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping of buttons to a new row */
    gap: 5px;
    /* Gap between buttons */
  }

  .site_table_blw_wrap button {
    flex: 1 1 calc(25% - 10px);
    /* Set button width to 25% minus gap for 4 buttons per row */
    /* max-width: calc(25% - 10px); Ensure maximum width is consistent with the calculated width */
    padding: 0 30px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    text-align: center;
  }

  .onpage_button_active {
    background-color: #007bff;
    /* Active button color */
    color: #ffffff;
    /* Text color for active button */
  }
}

@media (max-width: 500px) {
  .tab-header {
    width: 200px;
    margin: auto;
  }

  .button_row {
    display: column;
    flex-wrap: wrap;
    /* Allows wrapping of buttons to a new row */
    gap: 10px;
    /* Gap between buttons */
  }

  .button_container {
    display: block;
  }

  .site_table_blw_wrap button {
    flex: 1 1 calc(50% - 10px);
    /* Set button width to 50% minus gap for 2 buttons per row */
    padding: 10px;
    /* Adjust padding as needed */
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    /* Include padding and border in the button's total width */
  }

  .onpage_button_active {
    background-color: #007bff;
    /* Active button color */
    color: #ffffff;
    /* Text color for active button */
  }
}

.form-table {
  width: 100%;
  border-collapse: collapse;
}

.form-table td {
  padding: 8px;
  vertical-align: top;
}

.form-label {
  text-align: left;
  /* width: 30%; */
  padding-right: 20px;
}

.form-input {
  width: 70%;
}

.websitetype_inner,
.websiteauthen_inner {
  display: flex;
  align-items: center;
}

.form-buttons {
  text-align: center;
}

.error-value {
  color: red;
}

/* label.webtype{
    font-size: 16px;
  } */


.MuiDataGrid-cell .MuiDataGrid-withBorderColorr {
  background-color: white;
}

.css-fbreuu-MuiDataGrid-root .MuiDataGrid-row {
  width: 100px;
}

.css-1rkhofk-MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner {
  position: sticky !important;
}

.faq-body {
  width: 200px !important;
}

@media (max-width: 508px) {
  .menu_toggle_btn {
    top: 0px;
  }

  /* .page-health .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
      width: 20px;
      height: 20px;
      translate: 15px !important;
  } */
  .rest_scs_outer_container.confirm_sec_outer_container .rest_scs_ins_container img.Eso_logo {
    display: block;
    margin: 0 auto;
    /* width: 100px !important; */
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    /* transition: -2; */
  }

  .error_back_schema {
    /* font-size: 12px; */
  }

  .warning_back_schema {
    /* font-size: 12px; */
  }

  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    margin-bottom: 20px;
  }

  .css-heg063-MuiTabs-flexContainer {
    display: block !important;
    /* width:250px; */
  }

  .cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    padding: 15px 0px 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fe874e;
    height: 80px;
  }

  /* .faq_class {
      margin-top: 90px;
    } */
  .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2 {
    /* font-size: 14px; */
    margin-bottom: 100px;
  }

  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
    /* position: absolute;
      content: "";
      width: 1px;
      translate: 65px;
      height: 38px;
      background: #000000; */
    margin-left: 0px;
  }

  .css-wmy1p7-ReactDropdownSelect {
    min-width: 200px;
  }

  .cnt_hdr_blw_wrap {
    flex-direction: column;
  }

  .listdropdown {
    flex-direction: column;
    align-items: flex-start;
  }

  .dropdownValue {
    margin-top: 20px;
  }
}

@media(max-width:768px) {

  /* .change_password_eye .toggle-password {
   
    right: 60px;
   
} */
  .listdropdown {
    /* display: block; */
    margin-top: 20px;
  }

  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn {
    width: 150px;
  }

  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
    margin-left: -5px;
  }

  .css-wmy1p7-ReactDropdownSelect {
    min-width: 100px;
  }

  .cnt_hdr_blw_wrap {
    display: column;
  }

  .form-inner-data .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    width: 100%;
  }

  .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    border: 1px solid #0b2033;
    border-radius: 4px;
    width: 30px;
  }

  .cnt_audit_outer_wrap span._btn_icon_calender {
    border-left: 0px solid #ffffff40 !important;
    padding-left: 0px;
    padding-top: 6px;
  }
}

@media(min-width:1200px) {
  .bell-container {
    /* font-size: 2.5em; */
  }

  .frm_container {
    width: 100%;
    /* font-size: 18px; */
    /* font-weight: 700; */
  }

  .header_inner_btm_wrap {
    padding-right: 40px;
  }

  /* .brand_mention .row.pie_chart_row { */
  /* width: 45% !important; */
  /* height: 100px; */
  /* } */
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex !important;
  }

  .dropdownValue {
    margin-left: 30px;
  }
}

.otr-run-rpt-btn {
  display: flex;
}

@media (max-width:380px) {

  .cnt_hdr_top_wrap.spell-detail .cnt_hdr_top_lft_wrap h3 {
    width: 100px !important;
  }

  .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin-right: 25px !important;
  }

  .user-role-container .form-actions {
    display: flex;
    gap: 0px !important;
  }

  .edit-user .overall-container {
    padding: 20px;
    max-width: 200px;
    margin-right: 20px;
  }

  .form-select {
    padding: 10px;
    border: 1px solid #ff601f;
    border-radius: 5px;
    width: 200px !important;
  }

  .userdatas_inner {
    /* background: #fe874e 0% 0% no-repeat padding-box; */
    margin: 0 auto;
    text-align: center;
    max-width: 86% !important;
    border-radius: 17px;
    padding: 1em 0em 2em;
  }

  .acc-rpt-list img {
    margin-left: 10px !important;
    width: 230px !important;
  }

  .header_wrap {
    padding-left: 20px;
  }

  .cnt_audit_outer_wrap .int-src-link img {
    translate: 0px !important;
    margin-top: 150px !important;
  }

  /* .int-link-check .App {
      margin: 0px !important;
  } */

  /* .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {    
      width: 150px;
  } */
  .accessbility_score_warning_wrap {
    width: 200px !important;
  }

  .bell-container {
    position: relative;
    margin-top: 10px;
    margin-left: 0px !important;
    float: right;
  }

  .error_gwt ul.infoGraphic li .content {
    margin: 8em 0em 1em 1em !important;
    position: relative;
  }

  .error_gwt ul.infoGraphic li {

    padding: 0px !important;

  }

  select#drp_inner_top_wrap,
  select#drp_inner_bottom_wrap {
    width: 150px;
    margin-left: 20px !important;
  }

  /* .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
      translate: 45px ;
  } */
  .account_set_wrap {
    padding-right: 0px !important;
  }

  .rest_scs_outer_container h1.scs_tt {
    /* font-size: 22px !important; */
  }

  .rest_scs_outer_container.confirm_sec_outer_container button.sbt_btn {
    /* font-size: 12px; */
    width: 80%;
    padding: 10px 10px;
  }

  .rest_scs_outer_container.confirm_sec_outer_container .cnfrm p {
    /* font-size: 11px !important; */
  }

  /* .rest_scs_outer_container.confirm_sec_outer_container .cnfrm{
      min-width: 180px !important;
      translate: -90px !important;
    } */
  .rest_scs_outer_container.confirm_sec_outer_container button.sbt_btn {
    /* font-size: 12px !important; */
    width: 80% !important;
    padding: 10px 10px !important;
  }

  .rest_scs_outer_container.confirm_sec_outer_container input#otppas {
    /* font-size: 7.5px !important; */
  }

  /* .change_password_eye .toggle-password {
      right: 60px;
      translate: 50px !important;
  } */
  .FaEyeSlash {
    /* font-size: 10px !important; */
  }

  .change_password_eye .toggle-password {

    width: 30px !important;
  }

  .rest_scs_outer_container h1 {
    margin-top: 0px;
    /* font-size: 22px; */
    text-align: center;
    color: #fff;
    /* width: 100px !important;
      translate: -45px; */
  }

  /* .rest_scs_outer_container .rest_inst_wrap {
      width: 150px !important;
      translate: -86px !important;
    } */
  /* .confirm_sec_outer_container .rest_scs_ins_container img.Eso_rst_img {
      transform: translateX(-150px) !important;
  } */
  /* .rest_scs_outer_container.confirm_sec_outer_container .rest_scs_ins_container img.Eso_logo {
      translate: -55px;
  } */
  .newClass {
    width: 100% !important;
  }

  .val {
    width: 30% !important;
  }

  .schemaOutputeso .key_val_row {
    padding-bottom: 40px !important;
  }

  .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap {
    gap: 5px !important;
  }

  .App {
    margin: 0px;
  }

  .cnt_audit_outer_wrap button.site-audit::after {
    translate: 55px !important;
  }

  /* .cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap img {
      translate: -50px !important;
      margin-top: 150px !important;
    } */
  /* .acc-scr-dtl .cnt_hdr_top_rgt_wrap img {
      translate: 0px !important;
      margin-top: 100px !important;
    } */
  /* .cnt_audit_outer_wrap .site-adt-rpt img  {
      translate: -50px !important;
      margin-top: 150px !important;
      
    } */
  .spell-detail {
    height: 150px !important;
  }


  .reset_page_container.tab_otr_outer .tab_head_innr_container {
    /* margin: auto; */
    max-width: 70%;
    margin-left: 16px !important;
  }

  .brand_container g.MuiChartsLegend-root.MuiChartsLegend-column.css-1u0lry5-MuiChartsLegend-root {
    transform: translate(9px, 190px) !important;
  }
}

@media (min-width:600px) {
  .google_area_chart_wrap.brand_mention {
    display: flex;
    justify-content: center;
  }
}

/* for add keyword-saru */
@media (max-width:310px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 68% !important;
  }
}

@media (min-width:311px) and (max-width:330px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 62% !important;
  }
}

@media (min-width:331px) and (max-width:350px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 60% !important;
  }
}

@media (min-width:351px) and (max-width:360px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 58% !important;
  }
}

@media (min-width:361px) and (max-width:370px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 54% !important;
  }
}

@media (min-width:371px) and (max-width:380px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 54% !important;
  }
}

@media (min-width:381px) and (max-width:400px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 68% !important;
  }
}

@media (min-width:401px) and (max-width:420px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 64% !important;
  }
}

@media (min-width:421px) and (max-width:440px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 62% !important;
  }
}

@media (min-width:441px) and (max-width:460px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 60% !important;
  }
}

@media (min-width:461px) and (max-width:480px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 56% !important;
  }
}

@media (min-width:501px) and (max-width:530px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 46% !important;
    height: 26px;
  }
}

@media (min-width:531px) and (max-width:560px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 44% !important;
    height: 26px;
  }
}

@media (min-width:561px) and (max-width:580px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 44% !important;
    height: 26px;
  }
}

@media (min-width:581px) and (max-width:600px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 42% !important;
    height: 26px;
  }
}

@media (min-width:601px) and (max-width:620px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 40% !important;
    height: 26px;
  }

  /* label.webtype {
    display: inline-flex;
  } */
}

@media (min-width:621px) and (max-width:640px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 38% !important;
    height: 26px;
  }
}

@media (min-width:641px) and (max-width:690px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 36% !important;
    height: 26px;
  }
}

@media (min-width:691px) and (max-width:730px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 34% !important;
    height: 26px;
  }
}

@media (min-width:731px) and (max-width:760px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 32% !important;
    height: 26px;
  }
}

@media (min-width:761px) and (max-width:768px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 30% !important;
    height: 26px;
  }
}

@media (min-width:769px) and (max-width:780px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 37% !important;
    height: 26px;
  }
}

@media (min-width:781px) and (max-width:850px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 50% !important;
    height: 26px;
  }
}

@media (min-width:851px) and (max-width:1000px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    /* width: 52%!important; */
    height: 26px;
  }
}

@media (min-width:1331px) and (max-width:1500px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    /* width: 55%!important; */
    height: 26px;
  }
}

@media (min-width:1501px) and (max-width:1520px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 55% !important;
    height: 26px;
  }
}

@media (min-width:1521px) and (max-width:1900px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 56% !important;
    height: 26px;
  }
}

@media (min-width:1901px) and (max-width:2600px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 57% !important;
    height: 26px;
  }
}

@media (min-width:2601px) {
  .userdatas_inner.keywordform_outer .user_country input[type="text"] {
    width: 58% !important;
    height: 26px;
  }
}

/* for add brand responsiveness-saru */
@media (min-width:300px) and (max-width:500px) {
  .row.addbranded_terms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col21 .form-control {
    /* margin-left: -10px!important; */
    width: 140px !important;
    ;
  }
}

@media (min-width:501px) and (max-width:767px) {
  .row.addbranded_terms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col21 .form-control {
    /* margin-left: -10px!important; */
    width: 100px !important;
    ;
  }
}

@media (min-width:781px) and (max-width:1114px) {
  .row.addbranded_terms {
    /* font-size: 11px; */
  }
}

.dummyselect .react-dropdown-select-dropdown-handle.css-1yc4zyy-DropdownHandleComponent.e1vudypg0 {
  margin-left: 0px !important;
}

.accessbledetail .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  overflow-wrap: anywhere !important;
}

/* new edit */
.cnt_hdr_top_lft_wrap {
  line-height: 1.5;
  width: 70% !important;
  overflow-wrap: break-word !important;
}

@media (max-width: 500px) {
  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
    margin-left: 10px;
  }
}

.comp-url {
  margin-left: 4px;
}

.web-link {
  margin-left: 85px;
}

@media (max-width: 1024px) {
  .comp-url {
    margin-left: 0px;
  }
}

@media (max-width: 920px) {
  .websitename label {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  /* .comp-url{
    margin-left: 0px;
  } */
}

@media (min-width:868px) and (max-width:874px) {
  .websitename label {
    margin-left: 25px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 728px) {
  .comp-url {
    margin-left: 0px;
  }

  .web-link {
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .form-group.user_country.websitename input[type="text"] {
    /* width: 100%; */
    height: 37px;
    /* margin-left: 50px; */
  }

  .comp-url {
    margin-left: 0px;
  }

  .websitename label {
    margin-left: 50px !important;
    margin-right: 0px !important;
  }

  .user_country label {
    display: block !important;
  }
}

@media (min-width:625px) and (max-width:1111px) {
  .websitename label {
    /* margin-left: 80px !important; */
    margin-right: 0px !important;
  }

  .web-link {
    margin-left: 0px;
  }

  /* .user_country label{
    display: block!important;
  } */
}

.backlink_report_table .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
  overflow-wrap: anywhere !important;
}

.analysissummary_table .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
  padding-bottom: 40px;
  padding-top: 40px;
}

.MuiDataGrid-selectedRowCount.css-de9k3v-MuiDataGrid-selectedRowCount {
  display: none;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  position: absolute;
  right: 10px;
  bottom: -1px;
}

.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
  display: block !important;
}

/* new edit */
.cnt_hdr_top_lft_wrap {
  line-height: 1.5;
  width: 70% !important;
  overflow-wrap: break-word !important;
}

@media (max-width: 500px) {
  .cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
    margin-left: 10px;
  }
}

.form-group.user_country.websitename input[type="text"] {
  /* width: 60%; */
  /* height: 30px; */
}

.comp-url {
  margin-left: 20px;
}

.web-link {
  margin-left: 85px;
}

@media (max-width: 1024px) {
  .comp-url {
    margin-left: 0px;
  }
}

@media (max-width: 920px) {
  .websitename label {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  /* .comp-url{
    margin-left: 0px;
  } */
}

@media (min-width:868px) and (max-width:874px) {
  .websitename label {
    margin-left: 25px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 728px) {
  .comp-url {
    margin-left: 0px;
  }

  .web-link {
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .form-group.user_country.websitename input[type="text"] {
    /* width: 100%; */
    height: 37px;
    /* margin-left: 20px; */
  }

  .comp-url {
    margin-left: 0px;
  }

  /* .websitename label{
    margin-left: 50px!important;
    margin-right: 0px!important;
  } */
  .user_country label {
    display: block !important;
  }
}

@media (min-width:625px) and (max-width:1111px) {

  /* .websitename label {
    margin-left: 80px !important;
    margin-right: 0px !important;
  } */
  .web-link {
    margin-left: 0px;
  }

  /* .user_country label{
    display: block!important;
  } */
}

/* new edit 30-10-24*/
@media (min-width:769px) and (max-width:970px) {
  .form_next button {
    width: 60% !important;
  }

}

@media (max-width: 500px) {
  .add-form .overall-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 320px) {
  .add-form .overall-container {
    max-width: 200px;
  }

  .form-container [type="text"] {
    width: 180px;
  }
}

/* add domain form */
.frm_container table th {
  padding-bottom: 0px !important;
}

.frm_container table th label {
  float: left;
  padding-left: 40px;
}

@media (max-width: 912px) {
  .frm_container table th label {
    float: left;
    padding-left: 20px;
  }

  #html,
  #yes {
    margin-left: -20px;
  }
}

@media (max-width: 500px) {
  .frm_container table th label {
    float: left;
    padding-left: 20px;
  }

  /* .frm_container input[type="text"] {
      width: 100%!important;
  } */
}

@media (max-width: 310px) {
  .frm_container table th label {
    float: left;
    padding-left: 10px;
  }

  /* .frm_container input[type="text"] {
      width: 100%!important;
  } */
  #html,
  #yes {
    margin-left: -20px;
  }
}

/* add domain form-end */
/* google analytics*/
@media (min-width:769px) and (max-width:780px) {
  .form-group.domain_owner.domain_ownertext input {
    margin-top: 20px;
  }

  .copybutton {
    margin-top: 5px !important;
  }
}

@media (min-width:654px) and (max-width:670px) {
  .form-group.domain_owner.domain_ownertext input {
    width: 65% !important;
    ;
  }
}

@media (max-width: 654px) {
  .form-group.domain_owner.domain_ownertext input {
    /* margin-top: 20px; */
  }

  .copybutton {
    margin-top: 5px !important;
  }
}

.form_next .analytics_button {
  display: flex;
  justify-content: center;
}

.form_next button {
  margin: 0 10px;
}

@media (min-width:769px) and (max-width:970px) {
  .form_next button {
    margin: 10px 0px !important;
  }
}

@media (max-width: 768px) {
  .form_next button {
    margin: 5px 0px !important;
  }
}

/* google analytics-end*/
/* performance settings */
@media (min-width:781px) and (max-width:880px) {
  .user_experince_form_container .form_row {
    display: flex;
    margin-bottom: 25px;
    flex-wrap: wrap;
    /* Allows columns to wrap instead of overlap */
    gap: 40px;
    /* Adds spacing between columns to avoid crowding */
  }

  .user_experince_form_container .form_column {
    flex: 1 1 45%;
    /* Allows each column to take up about half of the container */
    min-width: 200px;
    /* Ensures a minimum width for each column */
    box-sizing: border-box;
    /* Ensures padding and borders don't increase column size */
  }
}

/* performance settings-end */
@media (min-width:272px) and (max-width: 768px) {
  .label1 {
    margin-left: -10px;
  }
}

@media (min-width:769px) and (max-width: 1030px) {
  .label1 {
    margin-left: -10px !important;
  }
}

/* new edit 01-11-24 */
/* .css-fdllt0-DropDown {
  position: absolute;
  top: 247px!important;
  left: 0px!important;
 } */
#alert-dialog-slide-description .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
  border: 1px solid #fff;
  /* position: unset;
    height: fit-content; */
}

@media (max-width: 500px) {
  .drp_outer_wrap .drp_inner_tp_wrap:after {
    margin-left: 7px;
  }
}

@media (min-width:1066px) and (max-width: 1080px) {
  .external_link_data_row .cols .content_col {
    margin-left: -10px;
  }
}

@media (min-width:930px) and (max-width: 970px) {
  .external_link_data_row .cols .content_col {
    margin-left: -10px;
  }
}

@media (min-width:910px) and (max-width: 930px) {
  .external_link_data_row .cols .content_col {
    margin-left: -10px;
  }
}

@media (min-width:830px) and (max-width: 855px) {
  .external_link_data_row .cols .content_col {
    margin-left: -15px;
  }
}

@media (min-width:769px) and (max-width: 829px) {
  .external_link_data_row .cols .content_col {
    margin-left: -15px;
  }
}

@media (max-width: 400px) {
  .external_link_data_row .cols .icon_col img {
    margin-left: -10px;
  }

  .external_link_data_row .cols .content_col {
    margin-left: -20px;
  }
}

@media (max-width: 300px) {
  .external_link_data_row .cols .icon_col img {
    margin-left: -15px;
  }

  .external_link_data_row .cols .content_col {
    margin-left: -15px;
  }
}

/* Technical>> Accessibility Reports>> Report>> accessibility-report-detail */
@media (max-width: 800px) {
  .performance_accordion_div .scan-results .scan-result__content .scan-code-example .scan-list .scan-list__item {
    overflow-wrap: break-word;
  }

  .performance_accordion_div .scan-results .scan-result__content {
    /* font-size: 14px; */
    padding: 10px;
  }
}

@media (max-width: 540px) {
  .performance_accordion_div .scan-results__section {
    display: flex;
    flex-direction: column;
  }

  .performance_accordion_div .scan-result__info {
    margin-left: 0px;
    margin-top: 10px;
  }
}

@media (min-width:551px) and (max-width: 1023px) {
  .performance_accordion_div .scan-results__section {
    display: flex;
    flex-direction: column;
  }

  .performance_accordion_div .scan-result__info {
    margin-left: 0px;
    margin-top: 10px;
  }
}

@media (min-width:1024px) and (max-width: 1439px) {
  .performance_accordion_div .scan-results__section {
    display: flex;
    flex-direction: row;
  }

  .performance_accordion_div .scan-result__info {
    margin-left: 5px;
    margin-top: 0px;
  }
}

@media (max-width: 400px) {
  #performance-metric .scan-result__header {
    /* font-size: 12px; */
  }

  .css-1m4ljmh-MuiTypography-root {
    /* font-size: 14px!important; */
    position: relative;
    left: 15px;
  }

  img.view_per_img {
    position: relative;
    left: -14px;
    width: 14px !important;
  }

  .performance_accordion_div .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0px !important;
  }

  .performance_accordion_div .scan-results .scan-result__content {
    /* font-size: 14px; */
    padding: 10px;
  }

  .performance_accordion_div .scan-title {
    /* font-size: 14px !important; */
  }

  .performance_accordion_div .scan-results .scan-result__content .scan-code-example .scan-list .scan-list__item {
    overflow-wrap: break-word;
  }

  .performance_accordion_div .scan-results__section {
    display: flex;
    flex-direction: column;
  }

  .performance_accordion_div .scan-result__info {
    margin-left: 0px;
    margin-top: 10px;
  }
}

/*file accessibility by kannan*/
@media (max-width:768px) {
  .cnt_hdr_content_box_outer.fileaccesstopinner {
    flex-direction: column;
  }

  .plagiarisam_missing_waring_wrap.filename {
    width: 209px;
    margin-bottom: 20px;
  }

  .plagiarisam_missing_waring_wrap {
    width: 208px;
  }
}

/* new edit 01-11-24-end*/
/* edit 04-11-24 */
.job_date_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow: visible;
}

.react-dropdown-select .css-fdllt0-DropDown {
  max-height: 200px !important;
}

.search_volume_table .css-jm5569-MuiDataGrid-root .MuiDataGrid-cell:first-child {
  position: relative !important;
  top: 50px !important;
}

@media (max-width:1025px) {
  .search_volume_table .css-jm5569-MuiDataGrid-root .MuiDataGrid-virtualScroller {
    overflow-x: auto !important;
  }
}

@media (max-width:500px) {

  .add_domain_list.frm_container input[type="text"],
  .add_domain_list.frm_container input[type="password"] {
    height: 37px;
    width: 250px !important;
    padding: 0 0 0 17px !important;
    border: 1px solid #ff601f;
  }
}

@media (min-width:921px) and (max-width: 1100px) {
  .form_next_cancel {
    width: 70% !important;
  }
}

@media (min-width:680px) and (max-width: 767px) {
  .add_domain_eye {
    right: 20px !important;
  }
}

@media (min-width:1400px) {
  .add_domain_eye {
    right: 20px !important;
  }
}

@media (min-width:311px) and (max-width: 760px) {
  select#drp_inner_top_wrap {
    width: 200px;
  }
}

@media (max-width:310px) {
  select#drp_inner_top_wrap {
    width: 120px;
    /* font-size: 10px; */
  }

  .cnt_audit_outer_wrap .cnt_hdr_top_wrap {
    padding: 15px 0px 15px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    background: #fe874e;
  }

  .cnt_audit_outer_wrap .cnt_hdr_top_lft_wrap h2 {
    /* font-size: 14px !important; */
  }
}

@media (max-width: 500px) {
  .header_inner_btm_wrap {
    gap: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 420px) {
  .header_wrap {
    padding-left: 25px !important;
  }
}

@media (max-width: 380px) {
  .header_wrap {
    padding-left: 10px !important;
  }
}

@media (max-width: 340px) {
  .top_icons_grp span.account_set_wrap svg {
    /* fill: white; */
    height: 50px;
    width: 20px;
  }
}

@media (min-width: 1200px) {
  .external_link_data_row .cols .content_col p {
    text-align: center;
    position: relative;
    top: 10px;
  }
}

@media(max-width:900px) and (min-width:768px) {
  .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {

    width: 400px !important;
    height: 480px;

  }
}

@media(max-width:600px) {
  .tab_otr_outer.reset_page_container {
    display: block;
  }
}

@media (max-width: 600px) {
  .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
    width: 400px !important;
    height: 450px !important;
    /* margin-left: 118px; */
  }
}

@media (max-width:380px) and (min-width:250px) {
  .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
    width: 200px !important;
    height: 200px !important;
    /* margin-left: 118px; */
  }

}

@media (max-width:380px) and (min-width:250px) {
  .reset_page_container.tab_otr_outer .tab_head_container_right img.PasswordRestGif {
    width: 200px !important;
    height: 200px !important;
    /* margin-left: 118px; */
  }

}

/* .frm_container input[type="text"]{
  max-width: 80% !important;
}
.tab_otr_outer .frm_otr_wrap input#password{
  max-width: 80% !important;
} */
.keywordlisttable .css-1235j5g {
  display: flex;
  align-items: center;
}

/* edit 04-11-24 -end*/


@media(max-width:600px) and (max-width:501px) {

  /* .tab_link_outr_wrap{
    display: block !important;

  } */
  .tab_otr_outer .tab_link_outr_wrap a {
    /* font-size: 12px !important; */
  }

  .tab_otr_outer .tab_link_outr_wrap a {
    margin-bottom: 20px;
  }

  .tab_otr_outer .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-height: 38px;
    padding: 9px 0px !important;
    width: 100px;
  }

  .tab_otr_outer .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableX.css-69z67c-MuiTabs-scroller {
    text-align: justify !important;
  }

  /* .forgot_remember_row{
  display: block !important;
} */
  .tab_otr_outer .css-heg063-MuiTabs-flexContainer {
    margin: unset !important;
    background: #ffffffde 0% 0% no-repeat padding-box;
    display: inline-block;
    border-radius: 6px;
    width: 200px !important;
  }
}

@media (max-width: 300px) {
  .tab-header {
    width: 165px;
  }
}

@media (max-width:500px) {
  .tab_otr_outer .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    /* font-size: 12px !important; */
  }
}

@media (max-width:750px) and (min-width:500px) {
  .tab_otr_outer .toggle-password {
    right: 5px !important;
  }
}

@media (max-width:500px) {
  .tab_otr_outer .toggle-password {
    right: 15px !important;
  }

  .form-group.domain_owner.domain_ownertext input {
    /* font-size: 12px !important; */
  }

}

@media (max-width:600px) {
  .tab_otr_outer .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
    /* font-size: 12px !important; */
  }
}

@media(max-width:300px) {
  .userdata h1 {
    /* font-size: 30px !important; */
  }

  .userdata_inner p {
    /* font-size: 20px !important; */
  }

  .outer-container {
    width: calc(100% - 20px) !important;
  }

  .confirm_sec_outer_container .rest_scs_ins_container img.Eso_rst_img {
    width: 230px !important;
  }

  .confirm_sec_outer_container .Eso_cnfrm_mail_outer_wrap {
    min-height: 200px !important;
  }

  .form-group.user_country.websitename input[type="text"] {
    /* width: 100%; */
    height: 37px;
    margin-left: 0 !important;
  }

  .websitename label {
    /* width: 19%; */
    /* font-size: 10px !important; */
  }
}

.change_password_eye button.toggle-password {
  background: none;
  transform: translateX(-100%);
}

/* end code */

@media (min-width: 780px) {
  .drop_menu {
    margin-top: 30px;
  }
}

@media (min-width: 501px) and (max-width: 1100px) {
  .drop_menu {
    width: 25% !important;
  }
}

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
  gap: 20px !important;
}

.highlight {
  background: #ff1010 0% 0% no-repeat !important;
  color: #ffffff !important;
  font-weight: bold;
}

.row-content-text {
  /* word-break: break-all; */
  word-break: break-word;
}

.row-content-text {
  /* word-break: break-all; */
  word-break: break-word;
}

.MuiDataGrid-columnHeaders {
  background: #ff601f !important;
}

.tabbuttonerr {
  color: white;
  background: #000000;
  margin-right: 15px;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(11px);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  background: #000000 !important;
  /* height: 90%; */
  /* box-shadow: 1px 1px 10px 4px #5b496db3; */
  /* padding: 20px; */
  /* max-height: '400px'; */
  overflow-y: 'auto';
  display: flex;
  align-items: center;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: unset;
  flex-direction: column;
  max-height: calc(100% - 64px);
  /* max-width: 600px; */
  /* width: 40%; */
  padding: 0px 30px 30px 30px;
  /* max-height: 508px; */
}

/* .job_date_filter .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper
{
  max-width: 470px !important;
  height: 50%;
  border-radius: 5px;
} */
.cnt_audit_outer_wrap {
  color: #ffffff !important;
  /* margin-top: 5%; */
}

.MuiDialogActions-root.MuiDialogActions-spacing button {
  /* font-size: 14px; */
  color: white;
  text-transform: capitalize;
  /* background: #e9e9e92e !important; */
  background: #ff601f;
  border: 0px;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: 'pointer';
}

span.react-dropdown-select-item {
  color: #0b2033;
  background: #ffffff;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
}

.react-dropdown-select-dropdown .react-dropdown-select-dropdown-position-bottom {
  position: absolute;
  top: 42.99431610107422px;
  left: -1px;
  border: 1px solid #ccc;
  width: 300px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow: auto;
  z-index: 9;
}

.react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect {
  border-radius: 4px;
  width: 300px;
}

/* .react-dropdown-select {
  width: 258px !important;
}
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom {
  width: 258px !important;
} */
.MuiDialogContent-root {
  overflow: auto !important;
}

.react-dropdown-select-dropdown {
  z-index: 990 !important;
  /* Ensure it’s above dialog layers */
  position: absolute !important;
  height: 80px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall {
  display: none;
}

.react-dropdown-select {
  /* width: 184% !important; */
  background-color: white;
  border: 1px solid #ff601f !important;
}

/* @media (max-width:768px) {  
  .react-dropdown-select {
    width: 100% !important;
    background-color: white;
    border: 1px solid black;
  } 
} */


.react-dropdown-select-content.react-dropdown-select-type-single {
  flex-wrap: nowrap !important;
}


/* .react-dropdown-select-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  } */

/* Mobile and smaller devices */
@media (min-width: 320px) and (max-width: 374px) {
  .react-dropdown-select.drop-organicsearch {
    width: 263px !important;
  }
}

/* Small tablets and larger phones */
@media (min-width: 375px) and (max-width: 424px) {
  .react-dropdown-select.drop-organicsearch {
    width: 318px !important;
  }
}

/* Tablets and small desktops */
@media (min-width: 425px) and (max-width: 767px) {
  .react-dropdown-select.drop-organicsearch {
    width: 368px !important;
  }
}

/* Larger tablets, small desktops */
@media (min-width: 768px) and (max-width: 1023px) {
  .react-dropdown-select.drop-organicsearch {
    width: 287px !important;
  }
}

/* Desktops and larger screens */
@media (min-width: 1024px) and (max-width: 1439px) {
  .react-dropdown-select.drop-organicsearch {
    width: 460px !important;
  }
}

/* Larger desktops */
@media (min-width: 1440px) and (max-width: 2559px) {
  .react-dropdown-select.drop-organicsearch {
    width: 460px !important;
  }
}

/* Very large displays */
@media (min-width: 2560px) {
  .react-dropdown-select.drop-organicsearch {
    width: 819px !important;
  }
}


.react-dropdown-select.drop-organicsearch.css-16vukcu.e1gzf2xs0 {
  padding: 0px 5px;
}


.MuiDataGrid-virtualScroller {
  background-color: white;
}


.MuiDataGrid-columnHeader .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeader--sortable .MuiDataGrid-withBorderColor {
  outline: none;
}

.cnt_audit_outer_wrap .cnt_hdr_top_wrap {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background: #ff601f !important;
  box-shadow: unset !important;
  /* border:1px solid #ff601f; */
}

.header_wrap {
  background-color: #fafafa !important;
  box-shadow: #737070 0px 1px 5px -2px !important;
}

.over_all_container,
.cnt_audit_outer_wrap {
  background-color: #fafafa !important;
}

.top_icons_grp span.account_set_wrap svg {
  fill: #ff601f;
}

/* select#drp_inner_top_wrap, select#drp_inner_bottom_wrap {
  background: #ff601f !important;
} */
.bell-container {
  margin-top: 10px;
}

.external_link_data_row .cols {
  background: #ffffff !important;
  color: #000 !important;
  border: 1px solid #ff601f !important;
  justify-content: center;
}

.external_link_data_row .cols .icon_col {
  width: 20%;
  padding: 7px 7px;
  /* background: #000; */
}

.external_link_data_row .cols:hover {
  background: #ff601f !important;
  /* border: 1px solid #ffffff !important; */
}

.external_link_data_row .cols.ranking_summary_col:hover {
  box-shadow: 0px 0px 7px 5px #ffffffd6 !important;
}

.site_audit_dashboard_outer .card_row .cols {
  /* background: #ffffff !important; */
  /* border: 1px solid #ff601f !important; */
  box-shadow: rgba(0, 0, 0, 0.1) 4px 5px 10px 4px;
  border-radius: 15px;
}

.site_audit_dashboard_outer div h3 {
  color: #0b2033 !important;
}

.site_audit_dashboard_outer .card_row .cols .heading {
  color: #0b2033 !important;
  font-size: 16px;
}

p.versus {
  color: #0b2033 !important;
}

.site_audit_dashboard_outer .card_row .site_data_row {
  /* background: #ffffff !important; */
}

.site_audit_dashboard_outer .card_row .cols .data {
  color: #0b2033 !important;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 0px 1px;
  background: #fff !important;
}

a.seometricdata {
  color: #0b2033 !important;
}

.dashboard_traffic_widget {
  color: #0b2033 !important;
}

.dashboard_traffic_switch button.site_rn_rpt_btn {
  background: #ffffff !important;
  color: #0b2033 !important;
  border: 1px solid #ff601f !important;
}

.dashboard_traffic_switch button.site_rn_rpt_btn.active {
  color: #ffffff !important;
}

.dashboard_traffic_widget .site_cnt_hdr_blw_wrap button:hover {
  color: #ffffff !important;
}

.root .header-outer-wrap {
  background-color: #ffffff !important;
  border: 1px solid #b6aeae36 !important;
}

.cnt_hdr_top_wrap {
  margin-top: 12px;
  color: #ff601f !important;
}

/* .site_cnt_hdr_blw_wrap button.site_rn_rpt_btn{
  border: 1px solid #ff601f !important;
  background: #ffffff !important;
  color: #000 !important ;
} */
button.site_rn_rpt_btn.active {
  color: #ffffff !important;
}

.keywordlisttable .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  background-color: #f4f3f3 !important;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  border: 5px solid #f2efef !important;
}

button.run_rpt_btn.rn_rpt_btn {
  border: 1px solid #ff601f !important;
  color: #fff !important;
  background: #ff601f !important;
}

.add_keyword_button_wrap {
  background: #ffffff !important;
  /* border: 1px solid #ff601f; */
  box-shadow: 0px 6px 6px #0000004D;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap button.run_rpt_btn.rn_rpt_btn:hover {
  background: #ff601f !important;
  color: #000 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  background: #ffffff !important;
  border: 2px solid #ff601f;
  /* width: auto; */
}

button.form_submit_btn {
  /* border: 1px solid #ff601f !important; */
  /* color: #ff601f !important; */
  /* background: #ffffff !important; */
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:after {
  background: #ff601f !important;
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn:hover {
  background: #ffffff !important;
}

.cnt_audit_outer_wrap button.run_rpt_btn.ch_date_btn {
  border: 1px solid #ff601f !important;
}

.cnt_audit_outer_wrap .cnt_hdr_blw_wrap {
  background: #ffffff !important;
  /* border: 1px solid #ff601f !important; */
  color: #0b2033 !important;
}

.tabs_graph_outer_wrap .MuiTabs-flexContainer {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.tabs_outer_wrap.tabs_outer_wrap_tab_btm_rem .MuiTabs-flexContainer {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

/* .MuiTabs-flexContainer.MuiTabs-flexContainer button {
  color: #000000 !important;
  border: 1px solid #ff601f !important;
  background: #ffffff !important;
} */
.MuiTab-textColorPrimary.Mui-selected {
  background: #ff601f !important;
}

.cnt_audit_outer_wrap .search_key_wrap button.run_rpt_btn.rn_rpt_btn {
  background: #ff601f !important;
}

.search_key_wrap .plagarism_analyzer_row .input_col .input_domain_field {
  border: 1px solid #ff601f;
}

.search_key_wrap .react-dropdown-select .drop-organicsearch {
  border: 1px solid #ff601f !important;
}

.MuiDialogActions-root .MuiDialogActions-spacing .run_rpt_btn.rn_rpt_btn {
  background: #ffffff !important;
}

.MuiDialogActions-root .run_rpt_btn.rn_rpt_btn button {
  background: #ffffff !important;
}

.cnt_audit_outer_wrap.ContentAuditWrap {
  color: #000000 !important;
}

/* .plagiarisam_missing_waring_wrap_databox {
  border: 1px solid #ff601f !important;
  background: #ffffff !important;
} */

.plagarism_analyzer_row.no_pad .input_col input {
  border: 1px solid #ff601f;
}

/* .plagiarisam_missing_waring_wrap {
  color: #ffffff !important;
  background: #000000 !important;
} */
.plagiarisam_missing_waring_wrap {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #ff601f;
}

/* .plagiarisam_scan_missing_waring_wrap {
  color: #fff !important;
  background: #000000 !important;
} */
.plagiarisam_scan_missing_waring_wrap {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #ff601f;
}

.onpage_button_active,
.cnt_hdr_blw_wrap.site_content_wrap_butn button:hover {
  background-color: #0b2033 !important;
  color: white !important;
  border: 1px solid #ffffff !important;
}

.cnt_hdr_blw_wrap.site_content_wrap_butn button {
  color: #0b2033;
  border: 1px solid #ff601f !important;
}

.cnt_hdr_content {
  color: #0b2033 !important;
}

button.run_rpt_btn.ch_date_btns {
  border: 1px solid #ff601f !important;
}

.wrap_trans_back .daterangepicker_wrap .react-datepicker__input-container input {
  border-radius: 5px !important;
  border: 1px solid #ff601f !important;
}

.google_branded_row.external_link_data_row .cols {
  border: 1px solid #ff601f !important;
  color: #000000 !important;
}

.external_link_data_row .cols:hover {
  background: #ff601f !important;
  color: #ffffff !important;
}

.plagarism_analyzer_row.no_pad .input_col input {
  border: 1px solid #ff601f;
}

.plagarism_analyzer_row.no_pad .input_col input {
  border: 1px solid #ff601f !important;
}

.pagehealth_missing_waring_wrap.plagiarisam_chart_outer {
  color: #fff !important;
}

input.react-dropdown-select-input.css-144wq72.e11wid6y0::placeholder {
  color: black !important;
}

input.react-dropdown-select-input.css-iornwb.e11wid6y0::placeholder {
  color: black !important;
}

.site_blw_wrap {
  box-shadow: rgb(142 136 136 / 84%) 0px 8px 6px -4px !important;
}

button.buttonView {
  border: 1px solid #ff601f !important;
  color: #ff601f !important;
}

.heading_font {
  font-family: Space Grotesk, sans-serif;
  /* font-size: 18px;*/
  /* font-size: 180%; */
  font-weight: 700;
}

.sub_heading_font {
  font-family: 'Poppins', sans-serif;
  /* font-size: 18px; */
  font-weight: 700;
}

.sub_heading1_font {
  font-family: 'Poppins', sans-serif;
  /* font-size: 17px; */
  font-weight: 700;
}

.text_font {
  font-family: 'Poppins', sans-serif;
  /* font-size: 15px; */
  font-weight: 400;
}


.keyword::placeholder {
  text-align: left;
}

.MuiTab-textColorPrimary.Mui-selected {
  background: #ff601f !important;
  z-index: 7;
  color: white !important;
}

button.run_rpt_btn.rn_rpt_btn.pagehealthbutton {
  border: 2px solid #fff !important;

}

.react-dropdown-select-item-selected {
  background: #ff601f !important;
}




@media (min-width: 320px) and (max-width: 374px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 150px !important;
  }
}

/* Small tablets and larger phones */
@media (min-width: 375px) and (max-width: 424px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 150px !important;
  }
}

/* Tablets and small desktops */
@media (min-width: 425px) and (max-width: 767px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 150px !important;
  }
}

/* Larger tablets, small desktops */
@media (min-width: 768px) and (max-width: 1023px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 153px !important;
  }
}

/* Desktops and larger screens */
@media (min-width: 1024px) and (max-width: 1439px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 425px !important;
  }
}

/* Larger desktops */
@media (min-width: 1440px) and (max-width: 2559px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 425px !important;
  }
}

/* Very large displays */
@media (min-width: 2560px) {
  .react-dropdown-select.undefined.css-16vukcu.e1gzf2xs0 {
    width: 425px !important;
  }
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.css-zrqpq1::after {
  border-bottom: none !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.css-zrqpq1::before {
  border-bottom: none !important;
}


/* .MuiDataGrid-virtualScrollerContent.css-0 {
  width: auto !important;
  height: 804.375px !important;
  min-height: auto !important;
} */

.tab_otr_outer .tab_head_container_lft {
  /* margin: 40px 0px; */
  margin: 0 auto !important;
}

.tab_otr_outer .newlogin {
  flex-basis: 50%;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 50px !important;
}

.tab_otr_outer h6.tab_header.newdata {
  color: #ff601f;
}

.tab_otr_outer img.login_tab_img.dataimg {
  background: unset !important;
  box-shadow: unset !important;
  border-radius: 7px;
  display: block;
  width: 40%;
  padding: 0px;
  margin: 0 auto;
}

.tab_otr_outer .frm_otr_wrap .newform_val {
  /* border-radius: 8px !important;
  padding: 12px 10px 12px 10px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  font-size: 13px !important;
  border: 1px solid #ff601f !important; */
}

h2.tab_header.newdata {
  color: #ff601f;
  display: flex;
  justify-content: center;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall {
  display: none;
}

img.login_lft_img:hover {
  background-color: unset !important;
  zoom: unset !important;
}

.tab_otr_outer .tab_head_container_lft {
  margin: 40px 0px;
  flex-basis: 50%;
  /* background: unset !important; */
  /* border-radius: 20px 20px 20px 20px; */
}

.tab_otr_outer h6.tab_header {
  padding: 16px 0px;
  text-align: center;
  margin: 0px;
  font-size: 27px;
  letter-spacing: 1px;
  color: #ff601f !important;
  opacity: 1;
}

.tab_otr_outer img.login_tab_img {
  background: #ffffff00 0% 0% no-repeat padding-box !important;
  box-shadow: unset !important;
  border-radius: unset !important;
  display: block !important;
  width: 45% !important;
  padding: unset !important;
  margin: 0 auto;
}

/* .tab_otr_outer .tab_head_container_right img.login_lft_img {
  width: 85% !important;
  object-fit: contain !important;
} */
.tab_otr_outer .frm_otr_wrap input.newformdata {
  border-radius: 5px !important;
  padding: 12px 10px 12px 10px !important;
  border: 0px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  font-size: 12px !important;
  border: 1px solid #ff601f !important;
  /* color: #ff0000; */
}


.MuiTabs-root.css-1xkjjjh-MuiTabs-root.signallbutton {
  display: ruby;
  margin-left: -20px;
}

.MuiTabs-root.signallbutton {
  display: ruby;
  /* margin-left: 10px; */
}

.MuiButtonBase-root .MuiTabScrollButton-root .MuiTabScrollButton-horizontal .Mui-disabled .MuiTabs-scrollButtons .css-1mf9qzl-MuiButtonBase-root-MuiTabScrollButton-root {
  display: none;
}

.tab-header {
  display: flex;
  justify-content: center;
}

/* .keywordform_outer .user_country select {
  color: #828282;
} */
/* ::-webkit-scrollbar-thumb {
  background: #ff601f;
  border-radius: 10px;
} */
.site_Chart_info_inner.summary_graph {
  margin-left: 120px !important;
}

.cnt_hdr_content_box_outer.pagehealth_box_outer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagehealth_box_outer .pagehealth_missing_waring_wrap.plagiarisam_chart_outer {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ff601f !important;
}

span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.MuiCheckbox-root {
  margin-left: -12px !important;
}

.table_links {
  color: #044792 !important;
  cursor: pointer;
}

div#webcrumbs {
  margin: 0px 75px !important;
}


table.errorreport {
  width: 100%;
  /* Table spans full container width */
  border-collapse: collapse;
  /* Ensures no double borders */
}

th,
td {
  padding: 10px;
  /* Adds consistent padding */
  text-align: left;
  /* Aligns text to the left */
}

thead th {
  background-color: #f8f9fa;
  /* Light gray background for headers */
  font-weight: bold;
  /* Bold headers */
}

/* tbody tr:nth-child(even) {
    background-color: #f1f1f1;
  } */

.border {
  border: 1px solid #ccc;
  /* Standard border color */
}

.bg-white {
  background-color: white;
  /* Maintains white background */
}

.rounded-lg {
  border-radius: 8px;
  /* Smooth corners for the table container */
}

.shadow {
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.p-4 {
  padding: 40px;
  /* Container padding */
}

.rest_scs_ins_container {
  max-width: 650px !important;
}




.drop_menu1 {
  position: absolute;
  top: 60px;
  background: #ffffff;
  padding: 10px;
  right: 1%;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #fe874e;
  width: 30%;
  /* min-height: 100px; */
  overflow: auto;
  height: 400px;
}


@media(max-width:728px) {
  .drop_menu1 {
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #fe874e;
    padding: 10px;
    position: absolute;
    right: 1%;
    top: 60px;
    width: 70%;
  }
}


.drop_menu1 .menu:hover {
  color: #ff601f;
  text-decoration: none;
}

.drop_menu1 .menu {
  border-bottom: 1px solid #f7f3f3;
}


.drop_menu1 .menu:hover span.pass_img svg {
  fill: #ff601f;
}




.drop_menu1 .menu {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  fill: #0b2033;
  color: #0b2033;
  cursor: pointer;
  text-decoration: none;
  /* font-size: 15px; */
  padding-bottom: 5px;
}



.notification-title {
  display: flex;
  justify-content: space-between;
  /* Positions the title and icons on the same line */
  align-items: center;
}

.icons {
  display: flex;
  gap: 10px;
}

.icon1:hover,
.icon2:hover {
  color: black;
}


.notification-message {
  margin-top: 5px;
  font-size: 0.9em;
  color: #555;
}


.drop_menu1 .menu span.pass_img {
  width: 10%;
  padding-right: 10px;
}


.drop_menu1 .menu {
  display: flex;
  /* align-items: flex-start; */
  margin: 3px 6px;
}

.onpage_highlight {
  opacity: 0.5 !important;
  /* Dim the button */
  cursor: not-allowed !important;
}

.urlcheck::placeholder {
  text-align: center !important;
}

.accessibility_type {
  width: inherit !important;
}

.accessibility_header {
  color: #000;
  text-align: center;
  font-size: 2em;
  font-weight: 500;
  margin-top: -2em;
}

/* .accessibility_type__control {
  width: 200px;
}

.accessibility_type__menu {
  width: 200px;
} */

.dashboard_pie_chart {
  background-color: #FFFFFF;
}


/* new Ui jan 2025 */
.side-nav {
  background: #ff601f;
  top: 0;
  z-index: 9999;
}

@media (min-width: 800px) {

  .side-nav {
    width: 75px;
    transition: width 1s;
  }

  .side-nav:hover {
    width: 230px;
  }

  .side_nav_txt {
    padding-left: 10px;
  }

}

.nav-sections {
  color: #000;
}

/* view report button */

.view-report-button {
  background-color: #ff601f;
  color: white;
  padding: 6px 6px;
  font-size: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  width: 115px;
}

.view-report-button:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(175, 129, 76, 0.4);
}


.table-pagination {
  /* margin-top: -40px; */
  display: flex;
  justify-content: end;
}

.table-pagination .MuiPaginationItem-root {
  background-color: #f0f0f0;
}

.table-pagination .MuiPaginationItem-root:hover {
  background-color: #ff601f;
}

.table-pagination .MuiPaginationItem-page {
  color: black;
}

.table-pagination .MuiPaginationItem-root.Mui-selected {
  background-color: #ff601f;
}

.header-domain-dropdown__control {
  width: 269px;
}

@media (max-width: 500px) {
  .header-domain-dropdown__control {
    width: 140px !important;
  }
}

.domain-dropdown-section {
  margin-left: auto;
}

/* table footer */

.table-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.table-footer-pagination {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-left: 12%;
}

.table-footer-select {
  margin-left: auto;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .table-footer-container {
    flex-direction: column;
    gap: 10px;
  }

  .table-footer-select {
    margin-left: 0;
    align-self: center;
  }
}





.MuiDataGrid-root--densityCompact .MuiDataGrid-cell {
  py: 8px !important;
  font-size: 14px;
}

.MuiDataGrid-root--densityStandard .MuiDataGrid-cell {
  py: 15px !important;
  font-size: 14px;
}

.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell {
  py: 22px !important;
  font-size: 18px;
}

.MuiDataGrid-columnHeaderTitle {
  white-space: normal;
  line-height: normal;
  font-size: 14px;
}

.MuiDataGrid,
[class^=MuiDataGrid] {
  border: none !important;
  /* outline: none !important; */
}

.MuiDataGrid-cell:first-child {
  position: unset;
  top: 0;
  left: 0;
  padding-left: 1.5rem;
  z-index: 999;
}

.MuiDataGrid-columnHeader:first-child {
  position: sticky;
  top: 0;
  left: 0;
  padding-left: 1.5rem;
  border: none;
  z-index: 999;
}

.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.MuiDataGrid-sortIcon {
  visibility: visible !important;
  opacity: 1 !important;
  transition: none;
}

.MuiDataGrid-row:hover {
  /* background-color: #FFECC8 !important; */
  background-color: #f2efef !important;
  border: none !important;
}

.MuiDataGrid-row.Mui-selected {
  background-color: #f2efef !important;
  border: none !important;
}

.MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer {
  -webkit-box-pack: unset;
  -webkit-justify-content: unset;
  justify-content: center;
  /* margin-left: 30px; */
}


.MuiDataGrid-iconButtonContainer {
  width: auto !important;
  /* Your desired style */
}

.MuiDataGrid-columnHeaderTitle {
  white-space: normal !important;
  /* Allows text wrapping */
  line-height: 1.2 !important;
  /* Adjusts line height */
  text-align: center !important;
  /* Centers the text */
  display: block !important;
  word-wrap: break-word !important;
}

.table-link-url {
  display: flex;
  word-break: break-all;
  justify-content: center;
  align-items: normal;
  text-align: center;
}


.MuiDataGrid-columnHeaderTitle {
  font-size: 16px !important;
}

.MuiDataGrid-cellContent {
  font-size: 15px;
}

.view-report-button {
  background-color: #0b2033;
  padding: 12px 15px;
  font-size: 14px;
  border-radius: 27px;
}

.MuiDataGrid-row {
  box-shadow: 2px 2px #f2efef;
}


.analysissummary_table .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  border: 5px solid #f2efef !important;
  /* box-shadow: 10px 10px #f2efef !important; */

}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
  background-color: #f4f3f3 !important;
  /* border-radius: 5px !important; */
  box-shadow: 10px 10px #f2efef !important;
  border: 3px solid #f2efef !important;

}

.MuiBreadcrumbs-separator {
  font-size: 27px;
}

.exportpdf {
  border: 1px solid #c9c7c7 !important;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 11px;
  font-size: 15px;
  fill: blue;
  border-radius: 25px;
  color: #ff601f;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.exportpdf:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(175, 129, 76, 0.4);
}

.exportpdf_outer {
  display: flex;
  gap: 9px;

}

.cnt_hdr_top_lft_wrap {
  margin-left: 13px;
}

.cnt_hdr_top_wraps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background: #f4f3f3 !important;
  padding: 10px !important;
}

.breadcrumb_outer {
  /* background:#f2efef; */
  margin: 12px 3px;
  /* padding:10px; */
  display: flex;
  justify-content: space-between
}

.spanlink {
  color: #044792 !important;
  display: flex;
  align-items: baseline;
}

.table-link {
  display: flex;
  flex-direction: column;
}

.runreportnew {
  letter-spacing: 0.8px !important;
  width: 150px !important;
  line-height: 12px !important;
  height: 37px !important;
  padding: 8px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  background: #ff601f !important;
  color: #fff !important;
}

@media (max-width: 500px) {

  .runreportnew {
    margin: 5px;
  }

}

.cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn {
  padding: 7px;
  background: #ff601f !important;
  font-weight: 500;
}

.cnt_audit_outer_wrap .cnt_hdr_top_rgt_wrap {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.internal_form_settings {
  display: flex;
  gap: 20px;
  align-items: flex-start
}

.correction_input_header {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

@media (max-width: 600px) {

  .breadcrumb_outer {
    display: block;
  }

  .exportpdf {
    border: 1px solid #c9c7c7 !important;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 11px;
    font-size: 15px;
    fill: blue;
    border-radius: 25px;
    color: #ff601f;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  }

  .exportpdf:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgba(175, 129, 76, 0.4);
  }

  .exportpdf_outer {
    display: flex;
    gap: 9px;

  }

  .cnt_hdr_top_wraps {
    display: block;
  }

  .cnt_audit_outer_wrap button.run_rpt_btn.rn_rpt_btn {
    padding: 7px !important;
    background: #ff601f !important;
    font-weight: 500 !important;
    margin-top: 20px !important;
  }

  .runreportnew {
    letter-spacing: 0.8px;
    width: 150px;
    line-height: 12px;
  }

  .innercommondomain {
    margin-top: 25px;
  }
}

@media(max-width:500px) {
  .cnt_hdr_top_rgt_wrap {
    display: block !important;
  }
}

.frm_container.plagarisminputbutton {
  display: flex;
  /* flex-direction: column; */
}

.heading_font {
  letter-spacing: 0.8px !important;
  font-weight: 900 !important;
  color: rgb(255, 96, 31) !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  border-radius: 18px !important;
  border-color: white !important;
  top: 10px !important;
}


.four_col {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.col {
  flex: 1;
  min-width: 200px;
  /* Ensures that columns don't shrink too much */
}

.radio-group {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.form-label {
  display: block;
  margin-bottom: 5px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  /* .row {
    flex-direction: column;
    gap: 10px;
  } */

  .col {
    width: 100%;
  }

  .radio-group {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .radio-group {
    flex-direction: column;
    gap: 10px;
  }
}

@media(max-width:800px) {
  .filter-section {
    display: block;
  }
}

@media(max-width:600px) {
  button.run_rpt_btn.rn_rpt_btn {
    margin-top: 54px;
  }

}

.bg-white.rounded-lg.shadow.p-4 {
  overflow-wrap: anywhere !important;
}

.standardreport_dropdown {
  width: 250px;
}

input.campaign-name {
  width: 250px !important;
  border: 1px solid #ff601f;
}

button.exportpdf {
  background: #f4f3f3 !important;
}

.MuiDataGrid-columnHeaders {
  background: #f4f3f3 !important;
}

.analysissummary_table .MuiDataGrid-columnHeaders {
  background: #f4f3f3 !important;
}

.dialog-action-button {
  margin-top: 25px;
}

.lists {
  border: 2px solid #ff601f;
}

.cardactivecontainer:hover .cardcontentactivecontainer {
  background-color: #ff601f !important;
}

.site_audit_dashboard_outer .card_row .cols .data:hover {
  background: #ff601f !important;
  font-weight: 700;
}

.cardactivecontainer {
  cursor: pointer;
}

button.runreportnews.active {
  letter-spacing: 0.8px !important;
  width: 150px !important;
  line-height: 12px !important;
  height: 37px !important;
  padding: 8px !important;
  font-weight: 500 !important;
  /* border-radius: 8px !important; */
  background: #ff601f !important;
  color: #fff !important;
}

button.runreportnews {
  letter-spacing: 0.8px !important;
  width: 150px !important;
  line-height: 12px !important;
  height: 37px !important;
  padding: 8px !important;
  font-weight: 500 !important;
  /* border-radius: 8px !important; */
  background: #ffffff !important;
  color: #ff601f !important;
  border: 1px solid #ff601f !important;
}

.googleanaclass {
  display: flex;
}

.link_cnt_hdr_blw_wrap.googleanlytics {
  display: flex;
  gap: 0px;
  justify-content: center;
}

.accessbility_button_tap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
textarea.input_domain_field {
  outline-color: #ff601f;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.newform_val {
  outline-color: #ff601f !important;
}